import React, { useState } from 'react'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
  Box
} from '@material-ui/core'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import CloseIcon from '@material-ui/icons/Close'
import WelloTabs from '../../../PrmitiveComponent/component/WelloTabs'
import PublishIcon from '@material-ui/icons/Publish'

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  menu: string[]
  buttonText?: string
  skipButtonText?: string
  backButtonText?: string
}

export const getType = () => [
  { id: 'Lab', title: 'Lab' },
  { id: 'Hospital', title: 'Hospital' }
]
export const getRole = () => [{ id: 'Agent', title: 'Agent' }]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    minWidth: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    overflow: 'Auto'
  },

  subHeaderPaper: {
    maxWidth: 960,
    minWidth: 960,
    height: 32,
    backgroundColor: '#f1f1f1',
    padding: theme.spacing(0)
  },

  topHeader: {
    fontSize: 16,
    display: 'inline'
  },
  buttonStyle: {
    fontSize: 14,
    display: 'inline'
  },
  box: {
    marginBottom: 10,
    display: 'inline-block'
  },
  ml10: {
    marginLeft: 10
  },

  distance: {
    marginTop: 10
  },

  footer: {
    marginTop: 80
  },

  addMore: {
    backgroundColor: theme.palette.background.paper
  }
}))

export const AddPeople: React.FC<IUnitAdmin> = ({
  children,
  count,
  menu,
  buttonText,
  skipButtonText,
  backButtonText
}: IUnitAdmin) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [contactList, setContactList] = useState([
    { unit: 'Lab', role: 'Agent', name: '', email: '', phone: '' }
  ])

  const handleChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
  }

  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target
    const list = [...contactList]
    list[index][name] = value
    setContactList(list)
  }

  const handleRemoveClick = (index: any) => {
    const list = [...contactList]
    list.splice(index, 1)
    setContactList(list)
  }

  const handleAddClick = () => {
    setContactList([
      ...contactList,
      { unit: '', role: '', name: '', email: '', phone: '' }
    ])
  }

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={0}>
          <Grid key={1} item>
            <Paper className={classes.mainPage}>
              {children}
              <Paper className={classes.subHeaderPaper} square={true}>
                <WelloTabs
                  preSelectedTab={selectedTab}
                  onTabChange={(e) => handleChangeTab(e)}
                  menu={menu}
                />
              </Paper>
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  {selectedTab === 'INVITE TO UNIT' && (
                    <Grid container justify='center'>
                      {contactList.map((x, i) => {
                        return (
                          <Grid key={i} item>
                            <br></br>

                            <div className={classes.box}>
                              <Controls.PhoneNumber
                                name='phone'
                                label='PHONE NUMBER'
                                placeholder='Phone Number'
                                type='number'
                                required={true}
                                value={x.phone}
                                onChange={(e: any) => handleInputChange(e, i)}
                                width={169}
                              />
                              &nbsp;
                              {contactList.length !== 1 && (
                                <React.Fragment>
                                  <Controls.WelloActionButton
                                    color='secondary'
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    <CloseIcon />
                                  </Controls.WelloActionButton>
                                  <br></br>
                                </React.Fragment>
                              )}
                              <div className={classes.ml10}>
                                {contactList.length - 1 === i && (
                                  <div
                                    className={classes.addMore}
                                    onClick={handleAddClick}
                                  >
                                    <Box alignItems='center' display='inline'>
                                      <Box>
                                        <Typography
                                          variant='h4'
                                          className={classes.addMore}
                                        >
                                          + Add Another User &nbsp; &nbsp;
                                          &nbsp; &nbsp;{' '}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        {' '}
                                        <Divider />{' '}
                                      </Box>
                                    </Box>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {selectedTab === 'BULK INVITE' && (
                      <Grid style={{ marginLeft: 10 }}>
                        <div className={classes.distance}></div>
                        <div className={classes.distance}></div>

                        <div style={{ display: 'inline-flex' }}>
                          &nbsp; &nbsp;
                          <Controls.WelloInputFile
                            style={{ display: 'none' }}
                            name='logo'
                            label='FILENAME'
                            icon={<PublishIcon />}
                            required={true}
                            accept='image/x-png,image/gif,image/jpeg'
                            fileType='Select CSV file'
                            helperText='Please follow the format of our CSV format'
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footer}></div>
              <Divider />
              <div className={classes.distance}></div>
              <Grid container justify='flex-end'>
                <div className={classes.buttonStyle}>
                  <Controls.WelloButton
                    type='button'
                    text={skipButtonText ?? 'Skip for Now'}
                    size='small'
                    variant='outlined'
                    backgroundColor='#ffffff'
                    color='#4543d9'
                  />
                  <Controls.WelloButton
                    type='button'
                    text={backButtonText ?? 'Back'}
                    size='small'
                    variant='outlined'
                    backgroundColor='#ffffff'
                    color='#4543d9'
                  />
                  <Controls.WelloButton
                    type='submit'
                    text={buttonText ?? 'Invite Users'}
                    size='small'
                    backgroundColor='#4543d9'
                    color='#ffffff'
                  />
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
