import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectProps,
  useTheme
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import React, { useState } from 'react'
import dotIcon from '../../../assets/images/dot_icon.png'
import WelloFormItemLabel from './WelloFormItemLabel'

export interface IProps {
  label: string
  type?: any
  error?: null | undefined
  onSelectionChanged?: any
  availableOptions: any[]
  required: boolean
  preSelectedOption?: any
  displayField?: string
  valueField?: string
  width?: number
  selectProps?: SelectProps
}

export const WelloSelect: React.FC<IProps> = ({
  label,
  type,
  error,
  onSelectionChanged,
  availableOptions,
  required,
  preSelectedOption,
  displayField,
  valueField,
  width,
  selectProps
}: IProps) => {
  const [selectedValue, setSelectedValue] = useState(
    preSelectedOption
      ? valueField
        ? preSelectedOption[valueField]
        : valueField
      : ''
  )
  return (
    <Grid container direction='column' justify='center' alignContent='stretch'>
      <Grid item xs={12}>
        <FormControl
          variant='outlined'
          {...(error && { error: true })}
          fullWidth
        >
          <WelloFormItemLabel
            title={label}
            displayDoneLabel={
              selectedValue !== undefined && selectedValue.length > 0
            }
          />
          {/*  <Typography
            variant='subtitle2'
            style={{
              color: useTheme().palette.text.primary,
              textTransform: 'uppercase'
            }}
          >
            {label} &nbsp;
            {selectedValue !== undefined && selectedValue.length > 0 && (
              <img src={dotIcon} alt='icon' height='10'></img>
            )}
          </Typography> */}
          <Select
            style={{
              borderRadius: 6,
              paddingTop: 0,
              paddingBottom: 0,
              color: useTheme().palette.text.primary,
              maxWidth: 480,
              // height: 38,
              width: width,
              fontSize: 13,
              backgroundColor: useTheme().palette.common.white
            }}
            margin='dense'
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            displayEmpty
            value={selectedValue}
            {...selectProps}
            onChange={(event) => {
              const changedValue = valueField
                ? availableOptions.find((item) => {
                    return item[valueField] == event.target.value
                  })
                : event.target.value
              onSelectionChanged(changedValue)
              setSelectedValue(event.target.value)
            }}
          >
            <MenuItem value='' dense disabled>
              {type}
            </MenuItem>
            {availableOptions.map((option: any) => (
              <MenuItem
                dense
                key={valueField ? option[valueField] : option}
                value={valueField ? option[valueField] : option}
              >
                {displayField ? option[displayField] : option}
              </MenuItem>
            ))}
          </Select>

          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  )
}
