import React from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Typography,
  Paper,
  makeStyles,
  Grid,
  Button,
  Avatar
} from '@material-ui/core'

export interface TitleProps {
  title: string
  height: number
  width: number
  backgroundColor?: string
  doctor: R4.IPractitioner
  specialisation: string
  amount?: number
  timeSlot?: string
}

const useStyles = makeStyles((theme) => ({
  tile: {
    color: '#4d4d4d',
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 10,
    marginTop: 20
  },

  large: {
    width: 60,
    height: 60,
    display: 'inline-flex',
    marginTop: 7,
    marginLeft: 7
  },

  drtitle: {
    color: '#4543d9',
    fontSize: 14,
    fontWeight: 600,
    marginTop: 20,
    textAlign: 'left'
  },

  specTitle: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 7
  },
  right: {
    marginLeft: 320,
    color: '#4543d9',
    fontSize: 14,
    fontWeight: 600,
    paddingTop: 10
  },

  rightTitle: {
    color: '#4543d9',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 20,
    textAlign: 'right',
    marginRight: 7
  },

  timing: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 600,
    marginRight: 7,
    textAlign: 'right'
  }
}))

export const WelloDoctorCard: React.FC<TitleProps> = ({
  title,
  width,
  height,
  backgroundColor,
  doctor,
  specialisation
}: TitleProps) => {
  const classes = useStyles()
  return (
    <Grid container>
      {title.length !== 0 && (
        <React.Fragment>
          <Grid>
            <Typography className={classes.tile}>{title}</Typography>
          </Grid>
          <Grid className={classes.right}>
            <Button color='primary'>modify</Button>
          </Grid>
        </React.Fragment>
      )}
      <Grid xs={10}>
        <Paper
          style={{
            height: height,
            width: width,
            backgroundColor: backgroundColor,
            borderRadius: 6
          }}
        >
          <Grid container spacing={0}>
            <Grid item spacing={0}>
              <Avatar
                className={classes.large}
                alt='Remy Sharp'
                src='tapan.jpg'
              />
            </Grid>

            <Grid item xs={12} sm container>
              <Grid item xs container direction='column' spacing={0}>
                <Grid item xs>
                  <Typography className={classes.drtitle}>
                    &nbsp;&nbsp;Dr. {doctor.name[0].given}
                  </Typography>
                  <Typography className={classes.specTitle}>
                    {specialisation} •In-person
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm container>
              <Grid item xs container direction='column' spacing={0}>
                <Grid item xs>
                  <Typography className={classes.rightTitle}>
                    &nbsp;&nbsp;₹1,000.00
                  </Typography>
                  <Typography className={classes.timing}>
                    03:00 - 03:30pm
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
