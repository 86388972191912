import React, { useRef, useState } from 'react'
import isObject from '../utils/isObject'

import { Box, Typography, Grid, IconButton } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import buildField from '../buildField'

import {
  displayConditionSatisfied,
  hasDisplayConditions
} from '../utils/fieldUtils'
import { pad } from 'lodash'
import { CloseSharp } from '@material-ui/icons'
import { isOnlyNotesVisible } from '../utils/observationFormUtils'

interface ObservationFormProps {
  namePrefix?: string
  schema: Schema
  formValues?: any
  name?: string
  index?: number
  singleLineDisplay?: boolean
  onDelete?: (
    onSuccess: (data: any) => void,
    onError: (data: any) => void
  ) => void

  displayDelete?: boolean
  getState?: any
  setValue?: any
}

export const ObservationForm: React.FC<ObservationFormProps> = (
  props: ObservationFormProps
) => {
  return (
    <Grid
      container
      style={{
        border: '1px solid #e0e0e0',
        padding: '0px',
        paddingRight: props.namePrefix ? '8px' : undefined,
        margin: '0px',
        minHeight: '40px'
      }}
      alignContent='stretch'
      alignItems='stretch'
    >
      <Grid
        item
        style={{
          display: 'flex',
          alignItems: 'center',

          borderRight: props.schema.properties['code'].hideField
            ? undefined
            : '1px solid #e0e0e0',
          margin: '0px',
          padding: '0px 10px'
        }}
        xs={props.schema.properties['code'].hideField ? undefined : 2}
      >
        {buildField(
          props.namePrefix != undefined && props.namePrefix.length > 0
            ? `${props.namePrefix}.code`
            : 'code',
          props.schema.properties['code'],
          0,
          undefined,
          undefined,
          undefined,
          props.getState,
          props.setValue,
          props.schema
        )}
      </Grid>
      <Grid
        item
        xs={
          props.schema.properties['code'].hideField
            ? 12
            : props.namePrefix === undefined
            ? 9
            : 10
        }
        style={{
          margin: props.schema.properties['code'].hideField ? '2px' : '0px',
          paddingRight: props.schema.properties['code'].hideField
            ? '16px'
            : undefined
        }}
      >
        <Grid
          container
          style={{
            marginLeft: '10px',
            alignSelf: 'center'
          }}
          alignContent='center'
          alignItems='center'
        >
          {!isOnlyNotesVisible(props.schema) &&
            Object.entries(props.schema.properties).map(
              (entry: [string, object], index) => {
                const key: string =
                  props.namePrefix != undefined && props.namePrefix.length > 0
                    ? `${props.namePrefix}.${entry[0]}`
                    : entry[0]
                const value: SchemaProp = entry[1] as SchemaProp

                if (value.name === 'code')
                  return <div style={{ display: 'none' }}></div>
                if (value.name === 'form-uuid')
                  return <div style={{ display: 'none' }}></div>
                if (value.type !== 'forms')
                  return buildField(
                    key,
                    value,
                    index,
                    props.formValues,
                    props.name,
                    props.index,
                    props.getState,
                    props.setValue,
                    props.schema
                  )

                if (value.type === 'forms') {
                  if (hasDisplayConditions(value.conditions)) {
                    console.log('----------has conditions-----------------')
                    if (
                      !displayConditionSatisfied(
                        value.conditions,
                        props.formValues,
                        props.schema,
                        props.name,
                        props.index
                      )
                    ) {
                      console.log(
                        '----------no display conditions-----------------'
                      )
                      return <> </>
                    }
                  }
                  return value.forms.map((form: Schema, index: number) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={form.uuid}
                        style={{
                          margin: '0px',
                          borderCollapse: 'collapse',
                          padding: '0px'
                        }}
                      >
                        <ObservationForm
                          namePrefix={`${value.name}[${index}]`}
                          schema={form}
                          formValues={props.formValues}
                          name={value.name}
                          index={index}
                          getState={props.getState}
                          setValue={props.setValue}
                        />
                      </Grid>
                    )
                  })
                }
              }
            )}{' '}
          {isOnlyNotesVisible(props.schema) &&
            Object.entries(props.schema.properties)
              .sort((a, b) => {
                if (props.namePrefix === undefined) {
                  return a[0].localeCompare(b[0])
                } else {
                  return a[0].localeCompare(b[0])
                }
              })
              .map((entry: [string, object], index) => {
                const key: string =
                  props.namePrefix != undefined && props.namePrefix.length > 0
                    ? `${props.namePrefix}.${entry[0]}`
                    : entry[0]
                const value: SchemaProp = entry[1] as SchemaProp

                if (value.name === 'code')
                  return <div style={{ display: 'none' }}></div>
                if (value.name === 'form-uuid')
                  return <div style={{ display: 'none' }}></div>
                if (value.type !== 'forms')
                  return buildField(
                    key,
                    value,
                    index,
                    props.formValues,
                    props.name,
                    props.index,
                    props.getState,
                    props.setValue,
                    props.schema
                  )

                if (value.type === 'forms') {
                  if (hasDisplayConditions(value.conditions)) {
                    console.log('----------has conditions-----------------')
                    if (
                      !displayConditionSatisfied(
                        value.conditions,
                        props.formValues,
                        props.schema,
                        props.name,
                        props.index
                      )
                    ) {
                      console.log(
                        '----------no display conditions-----------------'
                      )
                      return <> </>
                    }
                  }
                  return value.forms.map((form: Schema, index: number) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={form.uuid}
                        style={{
                          margin: '0px',
                          borderCollapse: 'collapse',
                          padding: '0px'
                        }}
                      >
                        <ObservationForm
                          namePrefix={`${value.name}[${index}]`}
                          schema={form}
                          formValues={props.formValues}
                          name={value.name}
                          index={index}
                          getState={props.getState}
                          setValue={props.setValue}
                        />
                      </Grid>
                    )
                  })
                }
              })}
        </Grid>
      </Grid>
      {!props.namePrefix && props.displayDelete && (
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px',
            padding: '0px'
          }}
        >
          <IconButton
            aria-label='remove member'
            size='small'
            color='primary'
            onClick={() => {
              props.onDelete(
                () => {},
                () => {
                  console.log('error')
                }
              )
            }}
          >
            <CloseSharp color='primary' fontSize='small' />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default ObservationForm
