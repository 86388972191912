import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'

interface ILoadingPageProps {
  message?: string
}

export const WelloLoadingIndicator: React.FC<ILoadingPageProps> = ({
  message
}: ILoadingPageProps) => {
  return (
    <Grid
      item
      container
      direction='column'
      xs={12}
      justify='center'
      alignContent='center'
      alignItems='center'
    >
      <Grid item>
        <CircularProgress></CircularProgress>
      </Grid>
      <Grid item>
        <Typography>{message ?? 'Loading...'}</Typography>
      </Grid>
    </Grid>
  )
}
