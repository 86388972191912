import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Calendar from 'react-calendar'
import { useState } from 'react'
// import 'react-calendar/dist/Calendar.css'

export interface IProps {
  value?: any
  onDateChange?: (changedDate: any) => void
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  TopPaper: {
    backgroundColor: theme.palette.divider,
    width: 300,
    border: 0,
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '1.125em',
    height: 'auto'
  },
  button: {
    margin: 0,
    border: 0,
    outline: 'none',
    height: 34,
    width: 24,
    borderRadius: '50%'
  },

  weekDays: {
    textAlign: 'center',
    textTtransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.75em',
    color: ' #4543d9'
  },
  weekend: {
    color: '#333333'
  },
  topHeader: {
    maxWidth: '100%',
    textAlign: 'center',
    padding: '0.75em 0.5em',
    // background: 'none',
    // background: 'none',
    '&:enabled': {
      '&:focus': {
        background: ' #4543d9'
      },
      '&:hover': {
        background: ' #4543d9'
      },
      '&:active': {
        background: ' #4543d9'
      }
    },
    '&:disabled': {
      backgroundColor: ' #f0f0f0'
    }
  },
  calendar__navigation: {
    marginBottom: '1em'
  },
  navigation_button: {
    marginTop: 20,
    minWidth: 10
  }
}))

export const WelloCalendar: React.FC<IProps> = ({
  value,
  onDateChange
}: IProps) => {
  const classes = useStyles()
  const [date, setValue] = useState(new Date())

  const handleInputChange = (date, event) => {
    onDateChange(date)
  }

  return (
    <Calendar
      value={date}
      className={[classes.TopPaper, classes.weekDays]}
      tileClassName={[classes.topHeader, classes.button]}
      showNavigation={true}
      next2Label={null}
      prev2Label={null}
      onChange={handleInputChange}
      onClick={handleInputChange}
    />
  )
}
