/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PublishIcon from '@material-ui/icons/Publish'
import { useState } from '@storybook/addons'
import React from 'react'
import {
  getNameOfDoctor,
  getTelecomOfDoctor
} from '../../../../utils/fhirHelpers/doctorHelper'
import {
  validateEmail,
  validateMobileNumber,
  validateDob,
  validateDoctorName,
  validateProfilePic,
  validateGender,
  validateConsultingFee,
  validateRegistration,
  splDataValidate,
  languageValidate,
  qualificationValidate,
  validateYearsOfExperience,
  validatePhoto
} from '../../../../utils/formValidators/expertFormValidators'
import {
  getTitle,
  splData,
  qualifications,
  languages
} from '../../../../utils/ServiceHelper/Services/ExpertService'
import { Controls } from '../../../PrmitiveComponent/component/Controls'
import {
  Form,
  UseForm
} from '../../../PrmitiveComponent/component/Helper/UseForm'

export interface IFormLabels {
  nameLabel?: string
  titleLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  photoLabel?: string
}
export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  submitButtonText?: string
  resetButtonText?: string
  onValidSubmit?: (expert?: any, doctor?: R4.IPractitioner) => void
  preAddedDoctor?: R4.IPractitioner
  preAddedExpert?: any
  onClickToCancel: (val: boolean) => void
  formLabels?: IFormLabels
  popupSate: boolean
}

const errorValues = {
  name: '',
  phone: '',
  email: '',
  address: ''
}

function getInitializeValues(doctor?: R4.IPractitioner, expert?: any) {
  let initialFValues = {
    id: '0',
    title: 'Dr.',
    name: '',
    gender: '',
    phone: '',
    email: '',
    photoUri: '',
    imgName: '',
    width: 300,
    consultationPrice: 0,
    regNo: '',
    yearsOfExperience: 0,
    specialization: [],
    medicalQualification: [],
    languages: [],
    type: ''
  }
  if (doctor != null || expert != null) {
    initialFValues = {
      id: doctor?.id ?? '',
      title: 'Dr',
      name: getNameOfDoctor(doctor) ?? '',
      gender: '',
      phone: getTelecomOfDoctor(doctor, R4.ContactPointSystemKind._phone) ?? '',
      email: getTelecomOfDoctor(doctor, R4.ContactPointSystemKind._email) ?? '',
      photoUri: '',
      imgName: '',
      width: 300,
      consultationPrice: 0,
      regNo: '',
      yearsOfExperience: 0,
      specialization: [],
      medicalQualification: [],
      languages: [],
      type: ''
    }
  }
  return initialFValues
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: '#cdcdcd',
    borderRadius: 6,
    overflow: 'Auto'
  },

  distance: {
    marginTop: 10
  },

  large: {
    width: 60,
    height: 60
  },

  cameraContainer: {
    color: '#333333',
    fontSize: 10
    // marginLeft: 275,
  },
  topHeader: {
    color: theme.palette.common.white,
    marginBottom: 5
  }
}))

export const AddExpert: React.FC<IUnitAdmin> = ({
  children,
  count,
  submitButtonText,
  resetButtonText,
  preAddedExpert,
  preAddedDoctor,
  onClickToCancel,
  onValidSubmit,
  formLabels = {},
  popupSate = false
}: IUnitAdmin) => {
  const classes = useStyles()
  const initialFValues: any = getInitializeValues(
    preAddedDoctor,
    preAddedExpert
  )
  // const [openPopup, setOpenPopup] = useState(popupSate)

  function validate(fieldValues = values) {
    const temp = { ...errors }
    console.log(fieldValues)
    if ('email' in fieldValues)
      temp.email = validateEmail(fieldValues.email, true)
    if ('name' in fieldValues) {
      temp.name = validateDoctorName(fieldValues.name, true)
    }
    if ('dob' in fieldValues) temp.dob = validateDob(fieldValues.dob, true)
    if ('phone' in fieldValues) {
      temp.phone = validateMobileNumber(fieldValues.phone, true)
    }
    if ('photoUri' in fieldValues)
      temp.photoUri = validateProfilePic(fieldValues.photoUri, true)

    if ('gender' in fieldValues)
      temp.gender = validateGender(fieldValues.gender, true)
    validatePhoto

    if ('photoUri' in fieldValues)
      temp.photoUri = validatePhoto(fieldValues.photoUri, true)

    if ('consultationPrice' in fieldValues)
      temp.consultationPrice = validateConsultingFee(
        fieldValues.consultationPrice,
        true
      )

    if ('yearsOfExperience' in fieldValues)
      temp.yearsOfExperience = validateYearsOfExperience(
        fieldValues.yearsOfExperience,
        true
      )

    if ('regNo' in fieldValues)
      temp.regNo = validateRegistration(fieldValues.regNo, true)

    if ('specialization' in fieldValues)
      temp.specialization = splDataValidate(fieldValues.specialization, true)

    if ('languages' in fieldValues)
      temp.languages = languageValidate(fieldValues.languages, true)

    if ('medicalQualification' in fieldValues)
      temp.medicalQualification = qualificationValidate(
        fieldValues.medicalQualification,
        true
      )

    setErrors({
      ...temp
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
    console.log('here 5')
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    uploadPic,
    handleDateChange,
    resetForm
  } = UseForm(initialFValues, errorValues, true, validate)

  function handleSubmit(e: any) {
    console.log('In validation')
    e.preventDefault()
    if (validate()) {
      console.log('validation success')
      const formData = {
        tid: '',
        name: `${values.title} ${values.name}`,
        gender: values.gender,
        phone: '+91' + values.phone,
        regNo: values.regNo,
        email: values.email,
        specialization: values.specialization,
        medicalQualification: values.medicalQualification,
        yearsOfExperience: values.yearsOfExperience,
        languages: values.languages,
        consultationPrice: values.consultationPrice,
        photoUri: values.photoUri
      }

      console.log('Converted patient value')
      console.log(formData)
      if (formData) {
        if (onValidSubmit) onValidSubmit(formData, undefined)
        popupSate = false
        resetForm()
      } else {
        console.log('validation error')
      }
    }
  }
  const cancel = () => {
    onClickToCancel(false)
  }
  const handleClose = () => {
    onClickToCancel(false)
  }
  return (
    <Dialog
      open={popupSate}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle
        id='scroll-dialog-title'
        style={{
          backgroundColor: useTheme().palette.primary.main,
          color: useTheme().palette.common.white,
          alignContent: 'center',
          textAlign: 'center',
          height: 48
        }}
      >
        <Typography variant='h3' className={classes.topHeader}>
          Add Expert
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: useTheme().palette.background.default,
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0
        }}
      >
        <Paper className={classes.mainPage} square={true} variant='outlined'>
          <div className={classes.distance}></div>
          <Form onSubmit={handleSubmit} title='Add Expert'>
            <Grid container className={classes.root} spacing={0}>
              <Grid item xs={12}>
                <Grid container justify='center' spacing={0}>
                  <Grid key={1} item>
                    <Grid container className={classes.root} spacing={0}>
                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          &nbsp; &nbsp; &nbsp;
                          {values.imgName.length === 0 && (
                            <Controls.WelloInputFile
                              style={{ display: 'none' }}
                              name='photoUri'
                              label='PHOTO'
                              error={errors.photoUri}
                              icon={<AccountCircle />}
                              required={true}
                              type='image/x-png,image/gif,image/jpeg'
                              fileType='Add Photo'
                              onChange={uploadPic}
                            />
                          )}
                          {values.imgName.length !== 0 && (
                            <>
                              <Box>
                                <Avatar
                                  alt='avatar'
                                  src={values.photoUri}
                                  className={classes.large}
                                />
                                <label>
                                  <Typography
                                    style={{
                                      color: useTheme().palette.primary.main,
                                      textTransform: 'uppercase'
                                    }}
                                    variant='h4'
                                  >
                                    Change Photo
                                  </Typography>
                                  <input
                                    id='file-input'
                                    type='file'
                                    name='photoUri'
                                    style={{ display: 'none' }}
                                    onChange={uploadPic}
                                    accept='image/*'
                                  />
                                </label>
                              </Box>
                            </>
                          )}
                          &nbsp; &nbsp;
                          <Controls.WelloSelectForm
                            name='title'
                            required={true}
                            label={formLabels?.titleLabel ?? 'TITLE'}
                            type='Select Title'
                            value={values.title}
                            options={getTitle()}
                            width={120}
                            onChange={handleInputChange}
                          />
                          &nbsp; &nbsp;
                          <Controls.WelloInput
                            name='name'
                            required={false}
                            label='NAME'
                            type='text'
                            value={values.name}
                            error={errors.name}
                            placeholder='Name'
                            width={250}
                            onChange={handleInputChange}
                            charsAllowed={30}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <div className={classes.distance}></div>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          &nbsp;&nbsp;
                          <Controls.WelloSelectGender
                            name='gender'
                            required={true}
                            label={formLabels?.genderLabel ?? 'GENDER'}
                            type='Select Gender'
                            value={values.gender}
                            width={250}
                            onChange={handleInputChange}
                            error={errors.gender}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <Controls.WelloInput
                            name='yearsOfExperience'
                            required={false}
                            label='WORK EXPERIENCE'
                            type='number'
                            value={values.yearsOfExperience}
                            error={errors.yearsOfExperience}
                            placeholder='Work Experience'
                            width={236}
                            onChange={handleInputChange}
                            charsAllowed={30}
                            
                          />
                        </Grid>
                      </Grid>

                      <br></br>
                      <Grid item xs={12}>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          &nbsp;&nbsp;{' '}
                          <Controls.PhoneNumber
                            name='phone'
                            label='PHONE NUMBER'
                            placeholder='Phone Number'
                            type='tel'
                            value={values.phone}
                            required={false}
                            width={250}
                            error={errors.phone}
                            onChange={handleInputChange}
                          />
                          &nbsp; &nbsp;
                          <Controls.WelloEmailInput
                            name='email'
                            label='EMAIL ADDRESS'
                            placeholder='Email Address'
                            type='email'
                            value={values.email}
                            error={errors.email}
                            required={true}
                            width={236}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>

                      <br></br>
                      <Grid item xs={12}>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloInput
                            name='consultationPrice'
                            required={false}
                            label='CONSULTATION FEE (In Rs)'
                            type='number'
                            value={values.consultationPrice}
                            error={errors.consultationPrice}
                            placeholder='Consultation Fee'
                            width={250}
                            onChange={handleInputChange}
                            charsAllowed={30}
                          />
                          &nbsp; &nbsp;
                          <Controls.WelloInput
                            name='regNo'
                            required={false}
                            label='REGISTRATION NUMBER'
                            type='text'
                            value={values.regNo}
                            error={errors.regNo}
                            placeholder='Registration Number'
                            width={236}
                            onChange={handleInputChange}
                            charsAllowed={30}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          &nbsp;
                          <Controls.WelloMultiSelectBox
                            name='specialization'
                            label='SPECIALIZATIONS'
                            value={values.specialization}
                            error={errors.specialization}
                            options={splData}
                            type='Select Specializations'
                            width={250}
                            onChange={handleInputChange}
                          />
                          {/* &nbsp; &nbsp; */}
                          <Controls.WelloMultiSelectBox
                            name='medicalQualification'
                            label='QUALIFICATIONS'
                            type='Select Qualifications'
                            value={values.medicalQualification}
                            error={errors.medicalQualification}
                            options={qualifications.sort()}
                            width={236}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={9}>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          <Controls.WelloMultiSelectBox
                            name='languages'
                            label='LANGUAGES'
                            type='Select Languages'
                            value={values.languages}
                            error={errors.languages}
                            options={languages.sort()}
                            width={250}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <div className={classes.footer}></div>
                <Divider />
                <div className={classes.distance}></div>
                <Grid container justify='flex-end'>
                  <div className={classes.buttonStyle}>
                    <Controls.WelloButton
                      type='button'
                      text={resetButtonText ?? 'Cancel (esc)'}
                      size='small'
                      variant='outlined'
                      backgroundColor='#fefefe'
                      color='#4543d9'
                      onClick={cancel}
                    />
                    <Controls.WelloButton
                      type='submit'
                      text={submitButtonText ?? 'Add Expert (Alt+S)'}
                      size='small'
                      variant='outlined'
                      backgroundColor='#4543d9'
                      color='#cdcdcd'
                    />
                  </div>
                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#cdcdcd' }}>
        <Controls.WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
          id='cancel'
        />
        <Controls.WelloButton
          type='button'
          text={submitButtonText ?? 'Add Expert (Alt+S)'}
          size='small'
          variant='outlined'
          backgroundColor='#4543d9'
          color='#cdcdcd'
          onClick={handleSubmit}
          id='add_expert'
        />
      </DialogActions>
    </Dialog>
  )
}
