import React, { useState } from 'react'
import {
  Typography,
  Paper,
  makeStyles,
  Grid,
  Button,
  Avatar
} from '@material-ui/core'
import { randomInt } from 'crypto'

export const fhirPatient = [
  {
    resourceType: 'Patient',
    identifier: [
      {
        use: 'usual',
        system: 'mrn-tenant1',
        value: 'R-1344'
      },
      {
        use: 'usual',
        system: 'webid',
        value: 'https://dnn2.solid.localhost/profile/card#me'
      }
    ],
    active: true,
    name: [
      {
        use: 'official',
        text: 'adam driver',
        family: ['driver'],
        given: ['adam']
      }
    ],
    telecom: [
      {
        system: 'phone',
        value: '+919900546645',
        use: 'mobile',
        rank: '1'
      },
      {
        system: 'email',
        value: 'adam@wellopathy.com',
        use: 'work',
        rank: '2'
      }
    ],
    gender: 'male',
    photo: 'adam.jpg',
    birthDate: '1999-01-01',
    _birthDate: {
      extension: [
        {
          url: 'estimated-birthDate',
          valueBoolean: true
        }
      ]
    },
    deceasedBoolean: false,
    address: [
      {
        use: 'home',
        state: 'Karnataka st',
        country: 'India'
      }
    ]
  },

  {
    resourceType: 'Patient',
    identifier: [
      {
        use: 'usual',
        system: 'mrn-tenant1',
        value: 'R-1345'
      },
      {
        use: 'usual',
        system: 'webid',
        value: 'https://dnn2.solid.localhost/profile/card#me'
      }
    ],
    active: true,
    name: [
      {
        use: 'official',
        text: 'Leena Deka',
        family: ['Deka'],
        given: ['Leena']
      }
    ],
    photo: 'leena.jpg',
    telecom: [
      {
        system: 'phone',
        value: '+918147910531',
        use: 'mobile',
        rank: '1'
      },
      {
        system: 'email',
        value: 'leena@wellopathy.com',
        use: 'work',
        rank: '2'
      }
    ],
    gender: 'female',
    birthDate: '1989-01-01',
    _birthDate: {
      extension: [
        {
          url: 'estimated-birthDate',
          valueBoolean: true
        }
      ]
    },
    deceasedBoolean: false,
    address: [
      {
        use: 'home',
        state: 'Karnataka st',
        country: 'India'
      }
    ]
  }
]

export interface TitleProps {
  title?: string
  height: number
  width: number
  backgroundColor: string
}

const useStyles = makeStyles((theme) => ({
  tile: {
    color: '#4d4d4d',
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 10,
    marginTop: 20
  },

  large: {
    width: 60,
    height: 60,
    display: 'inline-flex',
    marginTop: 7,
    marginLeft: 7
  },

  specTitle: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 7,
    marginTop: 30
  },
  right: {
    marginLeft: 320,
    color: '#4543d9',
    fontSize: 14,
    fontWeight: 600,
    paddingTop: 10
  },

  rightTitle: {
    color: '#4543d9',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 20,
    alignItems: 'right',
    marginRight: 7
  },

  timing: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 600,
    marginRight: 7,
    textAlign: 'right'
  },

  ageLabel: {
    height: 39,
    width: 59,
    borderRadius: 6,
    backgroundColor: '#ffffff',
    marginTop: 16,
    textAlign: 'center',
    marginLeft: 140
  },
  overlappimg: {
    height: 20,
    display: 'inline-flex',
    align: 'center',
    marginLeft: 5,
    marginTop: 14,
    fontSize: 12,
    fontWeight: 600
  }
}))

export const WelloPatientSquareCard: React.FC<TitleProps> = ({
  title,
  width,
  height,
  backgroundColor
}: TitleProps) => {
  const classes = useStyles()
  const [gender, setGender] = useState('male')
  return (
    <div>
      {fhirPatient.map((x: any, i: any) => {
        return (
          <Grid container key={randomInt(600)}>
            {/* <Grid>
          <Typography className={classes.tile}>{title}</Typography>
        </Grid>
        <Grid className={classes.right}>
          <Button color="primary">modify</Button>
        </Grid> */}
            <Grid xs={10}>
              <Paper
                style={{
                  height: height,
                  width: width,
                  backgroundColor: backgroundColor
                  //   borderRadius: ,
                  //   padding: theme.spacing(0),
                }}
              >
                <Grid container spacing={0}>
                  <Grid item spacing={0}>
                    <Avatar
                      className={classes.large}
                      alt='Remy Sharp'
                      src='tapan.jpg'
                    />
                  </Grid>

                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={0}>
                      <Grid item xs>
                        <Typography className={classes.specTitle}>
                          Kanti Shah
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm container>
                    <Grid item xs container direction='column' spacing={0}>
                      <Grid item xs={1}>
                        <Paper className={classes.ageLabel}>
                          {gender === 'male' && (
                            <Typography
                              color='primary'
                              className={classes.overlappimg}
                            >
                              <img
                                src='m.png'
                                alt=''
                                height={15.3}
                                width={5.33}
                              />{' '}
                              &nbsp;{25}y
                            </Typography>
                          )}

                          {gender === 'female' && (
                            <Typography
                              color='primary'
                              className={classes.overlappimg}
                            >
                              <img
                                src='f.png'
                                alt=''
                                height={15.3}
                                width={5.33}
                              />{' '}
                              &nbsp;{25}y
                            </Typography>
                          )}
                        </Paper>
                        {/* <Typography className={classes.rightTitle}>
                      &nbsp;&nbsp;₹1,000.00
                    </Typography>
                    <Typography className={classes.timing}>
                      03:00 - 03:30pm
                    </Typography> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      })}
    </div>
  )
}
