import { Box, TextField, Typography, TextFieldProps } from '@material-ui/core'
import moment from 'moment'

import React from 'react'
import WelloFormItemLabel from './WelloFormItemLabel'

interface WelloDateInputProps {
  title: string
  textProps: TextFieldProps
  displayDoneLabel?: boolean
}

export const WelloDateInput: React.FunctionComponent<WelloDateInputProps> = (
  props: WelloDateInputProps
) => {
  return (
    <Box display={'flex'} flexDirection='column' flexGrow={1}>
      <WelloFormItemLabel
        title={props.title}
        displayDoneLabel={props.displayDoneLabel}
      />
      <Box>
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          type='date'
          InputProps={{
            inputProps: {
              min: '1871-01-01',
              max: moment().format('YYYY-MM-DD')
            }
          }}
          {...props.textProps}
        ></TextField>
      </Box>
    </Box>
  )
}
