/* eslint-disable react/jsx-key */
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Typography,
  useTheme,
  makeStyles
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  getNameFromHumanName,
  getSpecializationsList
} from '../../utils/fhirHelpers/doctorHelper'
import { TopMenu } from './TopMenu'

const useStyles = makeStyles({
  root: {
    '&$selected': {
      backgroundColor: '#7986CB',
      mixBlendMode: 'color-burn',
      '&:hover': {
        backgroundColor: '#7986CB',
        mixBlendMode: 'color-burn'
      }
    }
  },
  selected: {}
})

export interface MultiSelectWithSearchOptionProps {
  availableOptions?: any[]
  displayField?: string
  onOptionsClicked?: () => void
  onValuesChanged: (practitioner: any) => void
  id?: string
  isSelected?: boolean
  placeholderLabel?: string
  valueField?: string
  selectedSchedule?: any
}

export const DoctorSelectWithSearch: React.FC<MultiSelectWithSearchOptionProps> = ({
  availableOptions: availableOptions,
  onValuesChanged,
  onOptionsClicked,
  id,
  isSelected,
  placeholderLabel,
  displayField,
  valueField,
  selectedSchedule
}: MultiSelectWithSearchOptionProps) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = React.useState<any>()
  const [searchTerm, setSearchTerm] = React.useState('')
  const [searchResults, setSearchResults] = React.useState([])
  const [name, setName] = React.useState('')
  const nonSelectedTextColor = 'blue'
  const nonSelectedBodyTextColor = 'black'
  React.useEffect(() => {
    const results = availableOptions.filter((item) => {
      if (displayField) {
        return (item.name as string)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      } else {
        return (item as string).toLowerCase().includes(searchTerm)
      }
    })
    setSearchResults(results)
  }, [searchTerm])

  function isItemSelected(item: any): boolean {
    if (selectedOptions) selectedOptions
    {
      if (item.id === selectedOptions) {
        isSelected = true
        return true
      } else {
        return false
      }
    }
  }

  function getName(name: string): string {
    const changedName = name.replace('Dr. ', 's')
    // setName(changedName)
    return changedName
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Box width='100%'>
        <Box
          style={{
            backgroundColor: useTheme().palette.common.white,
            opacity: 0.8
          }}
          p={1}
        >
          <Box>
            <TopMenu></TopMenu>
          </Box>
          <TextField
            fullWidth={true}
            variant='outlined'
            margin='dense'
            color='primary'
            type='search'
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            id={id}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              )
            }}
            style={{ backgroundColor: 'white', borderRadius: 4 }}
            placeholder={placeholderLabel ?? 'Search'}
          ></TextField>
        </Box>
        <Box>
          <List>
            {searchResults &&
              searchResults.map((item) => (
                <>
                  <ListItem
                    id={
                      valueField
                        ? (item[valueField] as string)
                        : (item as string)
                    }
                    key={valueField ? item[valueField] : item}
                    alignItems='center'
                    selected={isItemSelected(item)}
                    style={{ cursor: 'pointer', marginBottom: 0 }}
                    classes={{ root: classes.root, selected: classes.selected }}
                    onClick={(e) => {
                      if (onValuesChanged) {
                        setSelectedOptions(item.id)
                        onValuesChanged(item)
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src='test.jpg' />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant='subtitle1'
                            style={{
                              position: 'relative',
                              zIndex: 100,
                              color: isSelected
                                ? nonSelectedTextColor
                                : nonSelectedTextColor
                            }}
                          >
                            {item.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component='span'
                            variant='subtitle2'
                            style={{
                              position: 'relative',
                              zIndex: 100,
                              color: isSelected
                                ? nonSelectedBodyTextColor
                                : nonSelectedBodyTextColor
                            }}
                          >
                            {getSpecializationsList(
                              item.roleObject?.specialty ?? []
                            )}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
          </List>
        </Box>
      </Box>
    </Box>
  )
}
