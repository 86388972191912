import React, { useEffect, useRef, useState } from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { theme } from '../../Common/Theme/theme'
import { GoogleApiWrapper } from 'google-maps-react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { randomInt } from 'crypto'
import dotIcon from '../../../assets/images/dot_icon.png'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    height: 2
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        padding: '1px 14px'
      },
      input: {
        backgroundColor: 'white',
        padding: '1px 14px'
      }
    },
    MuiInputBase: {
      input: {
        backgroundColor: 'white'
      }
    }
  }
}))
export interface IProps {
  name: string
  label?: string
  type?: 'text' | 'number' | 'date' | 'email'
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  width?: number
  showingInfoWindow?: boolean
  activeMarker?: any
  selectedPlace?: any

  mapCenter?: {
    lat: 49.2827291
    lng: -123.1207375
  }
}

export const WelloAddress: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  width,
  required = false,
  showingInfoWindow,
  activeMarker,
  selectedPlace,
  mapCenter
}: IProps) => {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBLVHqBpK4pTUHkxRLctTj6a3nHrt1d-uI'
  )
  const handleChange = (value: any) => {
    onChange(value)
  }
  const handleSelect = (value: any) => {
    geocodeByAddress(value)
      .then((results: any[]) => getLatLng(results[0]))
      .then((latLng: any) => {
        console.log('Success', latLng)
      })
      .catch((error: any) => console.error('Error', error))
  }

  return (
    <ThemeProvider theme={theme}>
      {loaded}
      <PlacesAutocomplete
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <FormControl {...(error && { error: true })}>
              <Typography
                style={{
                  color: useTheme().palette.text.primary,
                  textTransform: 'uppercase'
                }}
                variant='subtitle2'
              >
                &nbsp;&nbsp;{label} &nbsp;
                {value !== undefined && value.length > 0 && (
                  <img src={dotIcon} alt='icon' height='10'></img>
                )}
              </Typography>
              <TextField
                underlineShow={false}
                id={name}
                variant='outlined'
                name={name}
                value={value}
                fullWidth
                color={theme.palette.primary.main}
                // type={type}
                required={required}
                size={size}
                placeholder={placeholder}
                clasName={classes.inputBoxStyle}
                // textProps={{
                //   placeholder:placeholder
                // }}
                InputProps={{
                  style: {
                    borderRadius: 6,
                    maxWidth: 480,
                    width: width,
                    // height: 38,
                    padding: '1px',
                    color: theme.palette.text.primary,
                    fontSize: 13,
                    backgroundColor: theme.palette.common.white,
                     '&::placeholder': {
                       color: theme.palette.text.primary,
                       fontSize: 13
                     },
                    '&::input': {
                      height: 2
                    }
                  }
                }}
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input'
                })}
                //   onChange={(event) => setQuery(event.target.value)}
                {...(error && { error: true, helperText: error })}
              />
            </FormControl>
            <div className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion: any) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                    key={randomInt(9087)}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </ThemeProvider>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBLVHqBpK4pTUHkxRLctTj6a3nHrt1d-uI'
})
