/* eslint-disable react/jsx-key */
import React from 'react'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
  Button
} from '@material-ui/core'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'

export interface IwelcomePageProps {
  appTitle: string
  unitType?: string
  orgName?: string
  title: string
  buttonText?: string
  listData: string[]
  onClickContinue: () => void
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  innerImage: {},
  innerPaper: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: theme.palette.background.paper
  },

  content: {
    marginLeft: 7,
    paddingTop: theme.spacing(1),
    color: theme.palette.primary.main
  },
  ol: { paddingLeft: 12 },
  list: {
    marginLeft: 10,
    color: theme.palette.primary.main
  },
  buttonStyle: {
    fontSize: 14,
    marginTop: 16
  }
}))

export const WelcomeModal: React.FC<IwelcomePageProps> = ({
  appTitle,
  unitType,
  orgName,
  title,
  listData,
  onClickContinue,
  buttonText
}: IwelcomePageProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justify='center'
      alignContent='stretch'
      alignItems='stretch'
    >
      {/* <Grid key={1} item>
              <img className={classes.innerImage} src={require('../assets/imgs/people/' + this.props.name) alt='logo' />
            </Grid> */}
      <Grid
        key={0}
        container
        item
        alignContent='stretch'
        style={{ flexGrow: 1 }}
      >
        <Paper className={classes.innerPaper}>
          <Typography variant='h5' color='primary'>
            {appTitle}{' '}
          </Typography>
          <br></br>

          {unitType === 'OrgAdmin' && (
            <React.Fragment>
              <Typography className={classes.content}>
                {title}
                <br></br>
              </Typography>
              <Typography className={classes.list} component={'div'}>
                <ol className={classes.ol}>
                  {listData.map((value, i) => (
                    <li key={value + i}>{value}</li>
                  ))}
                </ol>
              </Typography>
            </React.Fragment>
          )}

          {unitType === 'LabAdmin' && (
            <React.Fragment>
              <Typography className={classes.content}>
                {title}
                <br></br>
              </Typography>
              <Typography className={classes.list} component={'div'}>
                <ol className={classes.ol}>
                  {listData.map((value, i) => (
                    <li key={value + i}>{value}</li>
                  ))}
                </ol>
                <br></br>
                <br></br>
              </Typography>
            </React.Fragment>
          )}

          {unitType === 'LabAgent' && (
            <React.Fragment>
              <Typography className={classes.content}>
                {title}
                <br></br>
              </Typography>
              <Typography className={classes.list} component={'div'}>
                <ol className={classes.ol}>
                  {listData.map((value, i) => (
                    <li key={value + i}>{value}</li>
                  ))}
                </ol>
                <br></br>
              </Typography>
            </React.Fragment>
          )}

          <Divider />
          <Grid container justify='flex-end'>
            <div className={classes.buttonStyle}>
              <Button
                variant='contained'
                color='primary'
                disabled={!onClickContinue}
                onClick={() => {
                  onClickContinue()
                }}
                title={buttonText ?? 'Continue to Verify Email'}
              >
                {buttonText ?? 'Continue to Verify Email'}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
