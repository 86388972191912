/* eslint-disable react/display-name */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
  withStyles,
  useTheme,
  Box
} from '@material-ui/core'
import { Menu, MoreVert, Settings } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { SortIcon } from '../Card/SortIcon'
import maleIcon from '../../../assets/images/m.png'
import femaleIcon from '../../../assets/images/f.png'
import {
  getAgeOfPatient,
  getEmailOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient
} from '../../../utils/fhirHelpers/patientHelper'
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRowsProp
} from '@material-ui/data-grid'

const useStyles = makeStyles((theme) => ({
  root: {
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)'
      }
    }
  }
}))

export interface PatientDataWithAppointment {
  id: string
  age: string
  gender: string
  patientName: string
  profilePic: string
  email: string
  fhirPatientDetail: R4.IPatient
  phone: string
}

interface ICardProperties {
  patientData: R4.IPatient[]
  onMenuPressed?: (contextPatient: R4.IPatient) => void
  onPatientSelected?: (selectedPatient: R4.IPatient) => void
  lastAppointmentDate?: string
}

const columns: GridColDef[] = [
  {
    field: 'patientName',
    // headerName: 'Patient Name',
    width: 200,
    renderCell: (cellValues) => {
      return (
        <Typography variant='subtitle1' color='primary'>
          {cellValues.value}
        </Typography>
      )
    },
    renderHeader: () => (
      <Typography variant='subtitle1' color='primary'>
        {'Patient Name '}
      </Typography>
    )
  },
  {
    field: 'age',
    // headerName: 'Patient Name',
    width: 200,
    renderCell: (cellValues) => {
      return (
        <Typography variant='subtitle2' color='primary'>
          {cellValues.value}
        </Typography>
      )
    },
    renderHeader: () => (
      <Typography variant='subtitle1' color='primary'>
        {'Age '}
      </Typography>
    )
  },
  {
    field: 'gender',
    // headerName: 'Patient Name',
    width: 200,
    renderCell: (cellValues) => {
      return (
        <Typography variant='subtitle2' color='primary'>
          {cellValues.value}
        </Typography>
      )
    },
    renderHeader: () => (
      <Typography variant='subtitle1' color='primary'>
        {'Gender '}
      </Typography>
    )
  },

  {
    field: 'email',
    // headerName: 'Patient Name',
    width: 200,
    renderCell: (cellValues) => {
      return (
        <Typography variant='subtitle2' color='primary'>
          {cellValues.value}
        </Typography>
      )
    },
    renderHeader: () => (
      <Typography variant='subtitle1' color='primary'>
        {'Email '}
      </Typography>
    )
  }
]

export const PatientGridTableView: React.FC<ICardProperties> = ({
  patientData,
  onMenuPressed,
  onPatientSelected,
  lastAppointmentDate
}: ICardProperties) => {
  const convertedPatientData: PatientDataWithAppointment[] = patientData.map(
    (pat) => {
      const val: PatientDataWithAppointment = {
        id: pat.id,
        patientName: getNameOfPatient(pat),
        age: getAgeOfPatient(pat).toString(),
        gender: pat.gender,
        email: getEmailOfPatient(pat),
        fhirPatientDetail: pat,
        phone: getTelecomOfPatient(pat),
        profilePic: getProfilePicPatient(pat)
      }
      return val
    }
  )

  return (
    <Box style={{ display: 'flex', height: '100%' }}>
      <Box style={{ flexGrow: 1 }}>
        <DataGrid
          rows={convertedPatientData}
          columns={columns}
          pageSize={5}
          // checkboxSelection
          autoHeight
          showColumnRightBorder={false}
          hideFooter={true}
        />
      </Box>
    </Box>
  )
}
