/* eslint-disable react/jsx-key */
import React from 'react'
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  useTheme
} from '@material-ui/core'
import { R4 } from '@ahryman40k/ts-fhir-types'

interface IProps {
  patient?: R4.IPatient
  doctor?: R4.IPractitioner
  typeOfAppointment?: string
  time?: string
  color?: any
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  timeStyle: {
    color: useTheme().palette.text.disabled,
    fontSize: 10,
    fontWeight: 600,
    marginLeft: 5
  },
  paper: {
    height: 80,
    textAlign: 'left',
    backgroundColor: '#f4f4f4',
    marginLeft: 10
  },
  patientName: {
    color: useTheme().palette.common.black,
    fontSize: 14,
    fontWeight: 600
  },

  doctorStyle: {
    color: useTheme().palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 7
  },
  timeSlotStyle: {
    color: useTheme().palette.text.disabled,
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 7
  }
}))

export const DoctorPatientCard: React.FC<IProps> = ({
  patient,
  doctor,
  typeOfAppointment,
  time,
  color
}: IProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} square={true} variant='outlined'>
        <Grid
          container
          direction='row'
          spacing={1}
          style={{ marginLeft: 10, marginTop: 10 }}
        >
          <Grid item>
            <Divider
              orientation='vertical'
              style={{ height: 57, width: 4, backgroundColor: 'red' }}
            ></Divider>
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={1} justify='flex-start'>
              <Grid item xs>
                <Typography className={classes.patientName}>
                  {patient !== undefined && (
                    <span>
                      {' '}
                      {patient.name[0].given[0]} {patient.name[0].family}{' '}
                    </span>
                  )}
                </Typography>

                <Typography className={classes.doctorStyle}>
                  {patient !== undefined && (
                    <span>
                      {' '}
                      {doctor.name[0].prefix[0]} {doctor.name[0].given[0]}{' '}
                      {doctor.name[0].family} • General Physician
                    </span>
                  )}
                </Typography>

                <Typography className={classes.timeSlotStyle}>
                  {time} •{typeOfAppointment}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
