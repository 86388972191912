import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
    marginTop: 25,
    height: 35
  },
  secondary: {
    backgroundColor: 'red',
    '& .MuiButton-label': {
      color: 'black'
    }
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    '& .MuiButton-label': {
      color: theme.palette.primary.main
    }
  }
}))

export interface IProps {
  color: any
  children: any
  onClick: any
}

export const WelloActionButton: React.FC<IProps> = ({
  color,
  children,
  onClick
}: IProps) => {
  const classes = useStyles()
  return (
    <Button className={`${classes.root} ${classes[color]}`} onClick={onClick}>
      {children}
    </Button>
  )
}
