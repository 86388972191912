import React from 'react'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { WelloDoctorCard } from '../PersonComponent/WelloDoctorCard'

interface patientListProps {
  doctorData: any[]
  date?: string
  time?: string
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1)
  },
  paperNotification: {
    padding: 3,
    textAlign: 'center',
    backgroundColor: '#4543d9',
    color: '#FFFFFF'
  },
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    minWidth: 1305,
    minHeight: 500
  },
  distance: {
    marginTop: 10
  }
}))

export const ScheduleView: React.FC<patientListProps> = ({
  doctorData,
  date,
  time
}: patientListProps) => {
  const classes = useStyles()

  return (
    <div>
      <Typography align='left' variant='h6' color='textSecondary'>
        &nbsp;&nbsp;{date}{' '}
      </Typography>
      {time}
      {doctorData.map((doctor, i) => (
        <React.Fragment key={i}>
          <WelloDoctorCard
            height={76}
            width={565}
            backgroundColor='#fefefe'
            title=''
            doctor={doctor}
            specialisation={doctorData[i].name[0].given}
          />
          <div className={classes.distance}></div>
        </React.Fragment>
      ))}
    </div>
  )
}
