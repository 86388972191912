/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { OrgSetup } from './Modal/OrgSetup'
import { UnitAdmins } from '../Form/Modal/UnitAdmins'
import { AddUnit } from '../Form/Modal/AddUnit'
import { LabSetup } from '../Form/Modal/LabSetup'
import { AddPeople } from './Modal/AddPeople'
import { FinishLabSetup } from './Modal/FinishLabSetup'
import { AddExpert } from './Modal/AddExpert'
import { NewPatient } from './Modal/NewPatient'
import { LabNewPatient } from './Modal/LabNewPatient'
import { InvitePractitioner } from './Modal/InvitePractitioner'
import { AddService } from './Modal/AddService'
import ExistingCustomer from './Modal/ExistingCustomer'
import { WelloDoctorAvailability } from '../../LayoutComponent/PersonComponent/WelloDoctorAvailability'
import { CreateDoctorSchedule } from '../HomePage/Appointments/CreateDoctorSchedule'
import { theme } from '../../Common/Theme/theme'
import Popup from '../../PrmitiveComponent/component/Helper/Popup'

export interface IFormProps {
  children?: React.ReactNode
  subHeader?: React.ReactNode
  unitType?: string
  count: number
  menu: string[]
  content: string[]
  onClickToCancel?: any
  onClickContinue: () => void
  onClickBack: () => void
}

export const docList = [{ id: '1', name: 'Dr Raman Jha' }]

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    maxWidth: 1325,
    height: 'auto',
    backgroundColor: '#ffffff'
  }
}))

const Form: React.FC<IFormProps> = ({
  children,
  subHeader,
  unitType,
  count,
  menu,
  content,
  onClickToCancel,
  onClickContinue,
  onClickBack
}: IFormProps) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.paperContainer}>
        <br></br>
        <br></br>
        {unitType === 'orgAdmin' && (
          <OrgSetup
            children={children}
            count={count}
            content={content}
            onClickContinue={onClickContinue}
          />
        )}

        {unitType === 'unitAdmin' && (
          <UnitAdmins children={children} count={count} />
        )}

        {unitType === 'unit' && (
          <AddUnit
            children={children}
            count={count}
            onClickBack={onClickBack}
            onClickContinue={onClickContinue}
          />
        )}

        {unitType === 'Lab' && <LabSetup children={children} count={count} />}

        {unitType === 'AddPeople' && (
          <AddPeople children={children} count={count} menu={menu} />
        )}

        {unitType === 'FinishLab' && (
          <FinishLabSetup
            children={children}
            count={count}
            onClickContinue={onClickContinue}
          />
        )}

        {unitType === 'clinic' && (
          <NewPatient
            count={count}
            onClickToCancel={onClickContinue}
            // onClickToCancel={onClickToCancel}
            formLabels={{}}
            popupSate={true}
          />
        )}

        {unitType === 'existing' && (
          <ExistingCustomer children={children} count={count} />
        )}

        {unitType === 'docavail' && (
          <WelloDoctorAvailability children={children} count={count} />
        )}

        {unitType === 'doctorSchedule' && (
          <CreateDoctorSchedule
            popupSate={true}
            count={count}
            onClickToCancel={onClickToCancel}
            formLabels={{}}
            doctorList={docList}
          />
        )}
      </div>
    </ThemeProvider>
  )
}

export default Form
