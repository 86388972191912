import { R4 } from '@ahryman40k/ts-fhir-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import tripleDot from '../../../assets/images/trippleDot.png'
import { Controls } from '../../PrmitiveComponent/component/Controls'

export interface TitleProps {
  title?: string
  height?: number
  width?: number
  doctorList?: R4.IPractitioner[]
  onMatch?: (id: string[]) => void
  titleColor?: string
}

const useStyles = makeStyles((theme) => ({
  tile: {
    color: theme.palette.text.disabled,
    marginTop: 10,
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 10,
    textTransform: 'uppercase'
  },
  right: {
    marginLeft: 210,
    marginTop: 10
  },

  paper: {
    maxHeight: 130,
    maxWidth: 320,
    minWidth: 320,
    backgroundColor: '#ececec',
    scrollbarWidth: 'auto',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

export const LeftFilterOptions: React.FC<TitleProps> = ({
  title,
  width,
  height,
  doctorList,
  onMatch,
  titleColor
}: TitleProps) => {
  const classes = useStyles()

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    alert(value)
    onMatch(value)
  }
  return (
    <React.Fragment>
      <Grid container>
        <Grid>
          <Typography className={classes.tile}>{title}</Typography>
        </Grid>
        <Grid className={classes.right}>
          <img src={tripleDot} alt='trippleDot' height='20' width='20' />
        </Grid>
      </Grid>
      <Controls.WelloSearchFilter
        name='spl'
        label='Search'
        placeholder='Search'
        type='text'
        required={true}
        width={width}
        onChange={handleInputChange}
      ></Controls.WelloSearchFilter>
      {/* {doctorList !== undefined && (
        <div className={classes.paper}>
          {doctorList !== undefined &&
            doctorList.map((value, i) => (
              <Controls.WelloCheckbox
                key={i}
                name={doctorList[i].name[0].given}
                label={doctorList[i].name[0].given}
              ></Controls.WelloCheckbox>
            ))}
        </div>
      )} */}
    </React.Fragment>
  )
}
