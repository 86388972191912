import React, { useState } from 'react'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Box,
  Divider
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import CloseIcon from '@material-ui/icons/Close'
import Geocode from 'react-geocode'
Geocode.setApiKey('AIzaSyBub-Ru3cd4vj1TxkcC4anEEftL7JMXAiw')
Geocode.setLanguage('en')
Geocode.setRegion('in')
Geocode.enableDebug()

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  buttonText?: string
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant='outlined' {...props} />
}

export const getType = () => [{ id: 'Main', title: 'Main' }]

const OrgTypeAdd = [
  { id: 'self', title: 'Same Address as Organization' },
  { id: 'custom', title: 'Custom' }
]

const OrgTypeLogo = [
  { id: 'self', title: 'Same Logo as Organization' },
  { id: 'custom', title: 'Custom' }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    minWidth: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6
  },

  distance: {
    marginTop: 10
  },
  imgPaper: {
    width: 510,
    height: 83,
    backgroundColor: '#999999',
    justifyContent: 'center'
  },

  imgStyle: {
    width: 151,
    height: 48,
    marginLeft: 200
  },
  contactInfoHeader: {
    marginTop: 5,
    color: '#4d4d4d',
    fontSize: 16,
    fontWeight: 'bold'
  },
  topHeader: {
    fontSize: 16
  },

  inputBoxStyle: {
    borderRadius: '6',
    height: 15,
    backgroundColor: theme.palette.common.white
  },

  buttonStyle: {
    fontSize: 14
  },
  box: {
    marginBottom: 10,
    display: 'inline-block'
  },
  ml10: {
    marginLeft: 10
  },

  footer: {
    marginTop: 80
  },
  addMore: {
    backgroundColor: '#cdcdcd'
  },
  specialOutline: {
    borderColor: 'grey',
    borderWidth: 1,
    minWidth: 480
  },

  checkBox: {
    borderColor: 'grey',
    borderWidth: 1,
    width: 425
  }
}))

const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  if (reason === 'clickaway') {
    // return
  }
}

export const LabSetup: React.FC<IUnitAdmin> = ({
  children,
  count,
  buttonText
}: IUnitAdmin) => {
  const classes = useStyles()
  const [contactList, setContactList] = useState([
    { type: '', phone: '8147910531', email: 'hr@centralLabs.com' }
  ])
  const [latLong, setLatLong] = useState({ lat: 26.08, lng: 80.0004 })
  const [isMarker, setMarker] = useState(true)

  const handleInputChange = (
    e: { target: { name: any; value: any } },
    index: number
  ) => {
    const { name, value } = e.target
    const list = [...contactList]
    list[index][name] = value
    setContactList(list)
  }

  const handleAddressChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        console.log(lat, lng)
        latLong.lat = lat
        latLong.lng = lng
        setLatLong(latLong)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  const handleRemoveClick = (index: number) => {
    const list = [...contactList]
    list.splice(index, 1)
    setContactList(list)
  }

  const handleAddClick = () => {
    setContactList([
      ...contactList,
      { type: '', phone: '8147910531', email: 'hr@centralLabs.com' }
    ])
  }

  const handleMarkerClick = () => {
    setMarker(true)
  }

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={0}>
          <Grid key={1} item>
            <Paper className={classes.mainPage}>
              {children}

              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid key={12} item>
                      <br></br>
                      <Controls.WelloInputBox
                        name='name'
                        required={true}
                        label='LAB NAME'
                        placeholder='Lab Name'
                      />
                      <div className={classes.distance}></div>
                      <Controls.WelloInputBox
                        name='address'
                        required={true}
                        label='MAIN ADDRESS'
                        onChange={handleAddressChange}
                        placeholder='Main Address'
                      />
                      <div className={classes.distance}></div>

                      {/* <Controls.WelloMapPage
                        isMarkerShown={isMarker}
                        onMarkerClick={handleMarkerClick}
                        currentLocation={latLong}
                      /> */}

                      <Controls.WelloRadioGroup
                        name='orgAddress'
                        onChange={handleInputChange}
                        items={OrgTypeAdd}
                        value='self'
                      />

                      <div className={classes.distance}></div>

                      <Paper className={classes.imgPaper}>
                        <img
                          src='lab.jpg'
                          alt='im'
                          className={classes.imgStyle}
                        ></img>
                        <br></br>
                        <div
                          style={{ display: 'inline-flex', marginLeft: 210 }}
                        >
                          <div>
                            <Typography
                              style={{
                                color: 'red',
                                fontSize: 16,
                                fontWeight: 'bold'
                              }}
                            >
                              Remove
                            </Typography>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <Typography
                              style={{
                                color: 'blue',
                                fontSize: 16,
                                fontWeight: 'bold'
                              }}
                            >
                              Change
                            </Typography>
                          </div>
                        </div>
                      </Paper>

                      <div className={classes.distance}></div>
                      <Controls.WelloRadioGroup
                        name='labLogo'
                        onChange={handleInputChange}
                        value='self'
                        items={OrgTypeLogo}
                      />
                      <div className={classes.distance}></div>
                      <Typography className={classes.contactInfoHeader}>
                        &nbsp;Contact Information{' '}
                        <div className={classes.distance}></div>
                      </Typography>
                      {contactList.map((x, i) => {
                        return (
                          <Grid key={i} item>
                            <div className={classes.box}>
                              {/* <Controls.WelloSelect
                                name='type'
                                width={160}
                                label='TYPE'
                                type='Type'
                                availableOptions={getType()}
                                required={true}
                                onSelectionChanged={(e: any) =>
                                  handleInputChange(e, i)
                                }
                                value={x.type}
                              /> */}
                              &nbsp;&nbsp;
                              {/* <Controls.WelloInput
                                name='phone'
                                label='PHONE NUMBER'
                                placeholder='Phone Number'
                                type='number'
                                required={true}
                                onChange={(e: any) => handleInputChange(e, i)}
                                value={x.phone}
                                width={160}
                                // className={classes.ml10}
                              /> */}
                              &nbsp;&nbsp;
                              <Controls.WelloEmailInput
                                name='email'
                                label='EMAIL ADDRESS'
                                type='email'
                                placeholder='Email Address'
                                required={true}
                                // className={classes.ml10}
                                onChange={(e: any) => handleInputChange(e, i)}
                                value={x.email}
                                width={160}
                              />{' '}
                              &nbsp;
                              {contactList.length !== 1 && (
                                <React.Fragment>
                                  <Controls.WelloActionButton
                                    color='secondary'
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    <CloseIcon />
                                  </Controls.WelloActionButton>
                                  <br></br>
                                </React.Fragment>
                              )}
                              <div className={classes.ml10}>
                                {contactList.length - 1 === i && (
                                  <div
                                    className={classes.addMore}
                                    onClick={handleAddClick}
                                  >
                                    <Box alignItems='center' display='inline'>
                                      <Box>
                                        <Typography
                                          variant='h4'
                                          className={classes.addMore}
                                        >
                                          + Add More &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        {' '}
                                        <Divider />{' '}
                                      </Box>
                                    </Box>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Grid>
                        )
                      })}
                      <div className={classes.distance}></div>
                      <div className={classes.distance}></div>
                      <div style={{ display: 'inline-flex' }}>
                        {/*  <Controls.WelloCheckbox name='Summer' label='' /> */}
                        <Alert
                          icon={false}
                          severity='success'
                          className={classes.checkBox}
                          variant='outlined'
                          onClose={handleClose}
                        >
                          You must provide your main contact information. It is
                          recommended to add department specific contact
                          information as well so communications can be sent
                          efficiently.
                        </Alert>
                      </div>

                      <div className={classes.distance}></div>
                      <Typography className={classes.contactInfoHeader}>
                        &nbsp;Lab Cofiguration{' '}
                      </Typography>
                      <div style={{ width: 550 }}>
                        {/*  <Controls.WelloCheckbox
                          label='My Lab Processes test Samples on same Site'
                          name='sameSite'
                        />
                        <Controls.WelloCheckbox
                          name='other'
                          label='My Lab sends out Samples to be tested at other partner labs (lab acts as a collection center)'
                        /> */}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footer}></div>
              <Divider />
              <Grid container justify='flex-end'>
                <div className={classes.buttonStyle}>
                  <Controls.WelloButton
                    type='submit'
                    text={buttonText ?? 'Continue'}
                    size='small'
                    backgroundColor='#4543d9'
                    color='#ffffff'
                  />
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
