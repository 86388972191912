import moment from 'moment'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const MOBILE_REGEX = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[56789]\d{9}|(\d[ -]?){10}\d$/
const testEmailRegs =/^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/i

const mailReg= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
export function validateEmail(email: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Email Address is required' : ''
  if (email && email.length > 0) {
    const isValid = mailReg.test(email)
    if (!isValid) {
      res = 'Email is not valid'
    } else if(email.length >320)
    {
      res = 'Email should not be more than 320 characters'
    }
     else {
      res = ''
    }
  }


  return res
}


export function validateEmailForLab(email: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Email Address is required' : ''
  if (email && email.length > 0) {
    const isValid = mailReg.test(email)
    if (!isValid) {
      res = 'Email is not valid'
    } else if(email.length >320)
    {
      res = 'Email should not be more than 320 characters'
    }
     else {
      res = ''
    }
  }


  return res
}


export function validateMobileNumber(
  mobileNumber: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Phone Number is required' : ''
  if (mobileNumber && mobileNumber.length > 0) {
    const isValid = MOBILE_REGEX.test(mobileNumber)
    if (!isValid) {
      res = 'Phone Number is not valid'
    }
    else {
      res = ''
    }
  }

  return res
}

export function validateMobileNumberForAdd(
  mobileNumber: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Phone Number is required' : ''
  if (mobileNumber && mobileNumber.length > 0) {
    const isValid = MOBILE_REGEX.test(mobileNumber)
    if (!isValid) {
      res = 'Phone Number is not valid'
    }
    else if(mobileNumber.length>10) {
      res = 'Phone Number should be of 10 digits'
    }
    else
    {
      res = ''
    }
  }

  return res
}

export function validateGender(gender: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Gender is required' : ''
  if (gender && gender.length > 0) {
    res = ''
  }
  return res
}

export function validateLRNumber(lrNumber: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Lab Reference Id is required' : ''
  if (lrNumber && lrNumber.length > 30) {
    res = 'Lab Refereence should be maximum of 30 characters'
  }
  if (lrNumber && lrNumber.length > 0 && lrNumber.length<=30 ) {
    if(!lrNumber.trim())
    {
      res = 'Lab Reference Id is required'
    }
    else
    {
      res = ''
    }
  }
  return res
}



export function validateDob(dob: string, isMandatory: boolean): string {
  let res = isMandatory ? 'DOB is required' : ''
  if (dob && dob.length > 0) {
    if (dob > moment().format('YYYY-MM-DD')) {
      res = 'DOB should be till current date'
    } else if (dob < '1871-01-01') {
      res = 'DOB should be on or after 01-01-1871'
    } else {
      res = ''
    }
  }
  return res
}

export function validateName(name: string, isMandatory: boolean): string {
  let res = isMandatory ? 'First Name is required' : ''
  if (name && name.length > 100) {
    res = 'First Name should be maximum of 100 characters'
  }
  
  
  if (name && name.length > 0 && name.length <=100) {
    if(!name.trim())
    {
      res = 'First Name is required'
    }
    else
    {
      res = ''
    }
   
  }
  return res
}

export function validateMiddleName(middleName: string, isMandatory: boolean): string {
  let res = ''
  if (middleName && middleName.length > 100) {
    res = 'Middle Name should be maximum of 100 characters'
  }
  if (middleName && middleName.length > 0 && middleName.length <=100) {
    res = ''
  }
  return res
}

export function validateOtherData(otherData: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Value is required' : ''
  if (otherData && otherData.length > 50) {
    res = 'Value should be maximum of 50 characters'
  }
  if (otherData && otherData.length > 0 && otherData.length <=50) {
    res = ''
  }
  return res
}


export function validateLastName(
  lastName: string,
  isMandatory: boolean
): string {

  let res = isMandatory ? 'Last Name is required' : ''
  if (lastName && lastName.length > 100) {
    res = 'Last Name should be maximum of 100 characters'
  }
  if (lastName && lastName.length > 0 && lastName.length <=100) {
    if(!lastName.trim())
    {
      res = 'Last Name is required'
    }
    else
    {
      res = ''
    }
  }
  return res
}

export function validateAddress(
  address: string,
  isMandatory: boolean
): string {
  let res = ''
  if (address && address.length > 1024) {
    res = 'Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <=1024) {
    res = ''
  }
  return res
}

export function validateFathersName(
  fatherName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Father's Name is required` : ''
  if (fatherName && fatherName.length > 0 && fatherName.length <=300) {
    res = ''
  }
  if (fatherName &&  fatherName.length >300) {
    res = `Father's Name should be maximum of 300 characters`
  }
  return res
}

export function validateHusbandsName(
  fatherName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Husband's Name is required` : ''
  if (fatherName && fatherName.length > 0 && fatherName.length <=300) {
    res = ''
  }
  if (fatherName &&  fatherName.length >300) {
    res = `Husband's Name should be maximum of 300 characters`
  }
  return res
}

export function validateNationality(
  nationality: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Nationality is required` : ''
  if (nationality && nationality.length > 0) {
    res = ''
  }
  return res
}

// export function validateOccupation(
//   occupation: any,
//   isMandatory: boolean
// ): string {
//   let res = isMandatory ? `Nationality is required` : ''
//   if (occupation && nationality.length > 0) {
//     res = ''
//   }
//   return res
// }


export function validateMaritalStatus(
  maritalStatus: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Marital Status is required` : ''
  if (maritalStatus && maritalStatus.length > 0) {
    res = ''
  }
  return res
}

export function validateProfilePic(
  profilePic: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Photo is required' : ''
  if (profilePic && profilePic.length > 0) {
    res = ''
  }
  return res
}

export function validateDoctorName(
  dooctorId: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Doctor Name is required' : ''
  if (dooctorId && dooctorId.length > 0) {
    res = ''
  }
  return res
}

export function validateStartDate(
  startDate: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Start Date is required' : ''
  if (startDate && startDate.length > 0) {
    res = ''
  }
  return res
}

export function validateEndDate(endDate: string, isMandatory: boolean): string {
  let res = isMandatory ? 'End Date is required' : ''
  if (endDate && endDate.length > 0 ) {
    res = ''
  }
  return res
}

export function validateSlotDuration(
  duration: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Slot Duration is required' : ''
  if (duration && duration.length > 0) {
    res = ''
  }
  return res
}

export function validateStartTime(
  startTime: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Start Time is required' : ''
  if (startTime && startTime.length > 0) {
    res = ''
  }
  return res
}

export function validateEndTime(endTime: string, isMandatory: boolean): string {
  let res = isMandatory ? 'End Time is required' : ''
  if (endTime && endTime.length > 0) {
    res = ''
  }
  return res
}

export function validateTimeLimit(
  endTime: string,
  startTime: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'End Time is required' : ''
  if (endTime && startTime) {
    if (moment(endTime, 'HH.mm').isBefore(moment(startTime, 'HH:mm'))) {
      res = 'End Time Should be > Start Time'
    } else {
      res = ''
    }
  }
  return res
}

export function validateRegistration(
  regNo: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Registration Number is required' : ''
  if (regNo && regNo.length > 0) {
    res = ''
  }
  return res
}

export function validateConsultingFee(
  consultationPrice: number,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Consultation Fee is required' : ''
  if (consultationPrice === 0) res = 'Consultation Fee should be > 0'
  if (consultationPrice > 0) {
    res = ''
  }
  return res
}

export function splDataValidate(spl: string[], isMandatory: boolean): string {
  let res = isMandatory ? 'Specialization is Required' : ''
  if (spl && spl.length === 0) {
    res = 'Specialization is Required'
  }
  if (spl && spl.length > 0) {
    res = ''
  }
  return res
}

export function languageValidate(
  languages: string[],
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Language is Required' : ''
  if (languages && languages.length === 0) {
    res = 'Language is Required'
  }
  if (languages && languages.length > 0) {
    res = ''
  }
  return res
}

export function qualificationValidate(
  qualification: string[],
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Medical Qualification is Required' : ''
  if (qualification && qualification.length === 0) {
    res = 'Medical Qualification is Required'
  }
  if (qualification && qualification.length > 0) {
    res = ''
  }
  return res
}
