import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  GridSpacing
} from '@material-ui/core'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import CloseIcon from '@material-ui/icons/Close'
import {
  getNameOfDoctor,
  getTelecomOfDoctor
} from '../../../../utils/fhirHelpers/doctorHelper'
import { UseForm } from '../../../PrmitiveComponent/component/Helper/UseForm'
import { getRole } from '../../../../utils/ServiceHelper/Services/ExpertService'
import {
  validateEmail,
  validateMobileNumber,
  validateDoctorName
} from '../../../../utils/formValidators/expertFormValidators'
import {
  getFhirPractitionerObjectFromForm,
  getFhirPractitionerRoleObjectFromForm
} from '../../../../utils/formHelper'
import WelloTabs from '../../../PrmitiveComponent/component/WelloTabs'

const menu = ['INVITE TO ORGANIZATION', 'INVITE TO UNITS', 'BULK INVITE']

export interface IFormLabels {
  unitLabel?: string
  roleLabel?: string
  nameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
}

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  popupSate: boolean
  cancelButtonText?: string
  inviteButtonText?: string
  formLabels?: IFormLabels
  onValidSubmit?: (
    practitioner?: R4.IPractitioner[],
    practitionerRole?: R4.IPractitionerRole[]
  ) => void
  preAddedPractitioner?: R4.IPractitioner
  preAddedPractitionerRole?: R4.IPractitionerRole
  onClickToCancel: (val: boolean) => void
}

const errorValues = {
  name: '',
  phone: '',
  email: '',
  address: ''
}

function getInitializeValues(
  practitioner?: R4.IPractitioner,
  practitionerRole?: R4.IPractitionerRole
) {
  let initialFValues = {
    id: '0',
    name: '',
    phone: '',
    email: '',
    role: 'doctor'
  }
  if (practitioner != null || practitionerRole != null) {
    initialFValues = {
      id: practitioner?.id ?? '',
      name: getNameOfDoctor(practitioner) ?? '',
      phone:
        getTelecomOfDoctor(practitioner, R4.ContactPointSystemKind._phone) ??
        '',
      email:
        getTelecomOfDoctor(practitioner, R4.ContactPointSystemKind._email) ??
        '',
      role: ''
    }
  }
  return initialFValues
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    overflow: 'Auto'
  },

  topHeader: {
    fontSize: 16,
    display: 'inline'
  },
  box: {
    marginBottom: 10,
    display: 'inline-block'
  },
  ml10: {
    marginLeft: 10
  },

  distance: {
    marginTop: 10
  },
  subHeaderPaper: {
    height: 32,
    backgroundColor: '#f1f1f1'
  }
}))

export const InvitePractitioner: React.FC<IUnitAdmin> = ({
  children,
  count,
  cancelButtonText,
  onValidSubmit,
  preAddedPractitioner,
  preAddedPractitionerRole,
  inviteButtonText,
  formLabels,
  popupSate = false,
  onClickToCancel
}: IUnitAdmin) => {
  const classes = useStyles()
  const [practitionerList, setPractitionerList] = useState([
    { name: '', email: '', phone: '', role: 'doctor' }
  ])
  const [openPopup, setOpenPopup] = useState(popupSate)
  const [selectedTab, setSelectedTab] = React.useState(menu[0])

  const [spacing, setSpacing] = React.useState<GridSpacing>(0)
  const initialFValues: any = getInitializeValues(
    preAddedPractitioner,
    preAddedPractitionerRole
  )

  function validate(fieldValues = values) {
    const temp = { ...errors }
    console.log(fieldValues)
    if ('email' in fieldValues)
      temp.email = validateEmail(fieldValues.email, true)
    if ('name' in fieldValues) {
      temp.name = validateDoctorName(fieldValues.name, true)
    }
    if ('phone' in fieldValues) {
      temp.phone = validateMobileNumber(fieldValues.phone, true)
    }

    setErrors({
      ...temp
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, errors, setErrors } = UseForm(
    initialFValues,
    errorValues,
    true,
    validate
  )
  const cancel = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const handleClose = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }

  const handleRemoveClick = (index: any) => {
    const list = [...practitionerList]
    list.splice(index, 1)
    setPractitionerList(list)
  }

  const handleAddClick = () => {
    setPractitionerList([
      ...practitionerList,
      { name: '', email: '', phone: '', role: 'doctor' }
    ])
  }

  const handleInputChangeArray = (e: any, index: any) => {
    const { name, value } = e.target
    const list = [...practitionerList]
    list[index][name] = value
    setPractitionerList(list)
    console.log(practitionerList)
  }

  const handleChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
  }

  function handleSubmit(e: any) {
    e.preventDefault()
    const patList: React.SetStateAction<R4.IPractitioner[]> = []
    const patRoleList: React.SetStateAction<R4.IPractitionerRole[]> = []
    for (let i = 0; i < practitionerList.length; i++) {
      const pat:
        | R4.IPractitioner
        | undefined = getFhirPractitionerObjectFromForm(practitionerList[i])
      if (pat) patList.push(pat)
      const patRole:
        | R4.IPractitionerRole
        | undefined = getFhirPractitionerRoleObjectFromForm(practitionerList[i])
      if (patRole) patRoleList.push(patRole)
      console.log(pat)
    }
    if (patList.length > 0 && patRoleList.length > 0) {
      if (onValidSubmit) onValidSubmit(patList, patRoleList)
    } else {
      console.log('validation error')
    }
  }

  return (
    <Dialog
      open={openPopup}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle
        id='scroll-dialog-title'
        style={{
          backgroundColor: useTheme().palette.primary.main,
          color: useTheme().palette.common.white,
          alignContent: 'center',
          textAlign: 'center',
          height: 48
        }}
      >
        <Typography variant='h3' className={classes.topHeader}>
          Invite People
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: useTheme().palette.background.default,
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0
        }}
      >
        <Paper className={classes.mainPage} square={true} variant='outlined'>
          <form autoComplete='off'>
            <Grid container className={classes.root} spacing={spacing}>
              <Grid item xs={12}>
                <Paper className={classes.subHeaderPaper}>
                  <WelloTabs
                    preSelectedTab={selectedTab}
                    onTabChange={(e) => handleChangeTab(e)}
                    menu={menu}
                  />
                </Paper>
                {selectedTab === menu[0] && (
                  <Grid container justify='flex-start'>
                    {practitionerList.map((x, i) => {
                      return (
                        <Grid key={i} item>
                          <Grid key={1} item>
                            <div className={classes.box}>
                              <Box p={1}>
                                <Controls.WelloInput
                                  name='name'
                                  label={formLabels?.nameLabel ?? 'NAME'}
                                  placeholder='Name'
                                  type='text'
                                  required={true}
                                  onChange={(e: any) => {
                                    handleInputChangeArray(e, i)
                                  }}
                                  width={200}
                                  charsAllowed={30}
                                />
                                &nbsp;&nbsp;
                                <Controls.WelloEmailInput
                                  name='email'
                                  label={
                                    formLabels?.emailLabel ?? 'EMAIL ADDRESS'
                                  }
                                  placeholder='Email'
                                  type='email'
                                  required={true}
                                  // value={x.email}
                                  error={errors.email}
                                  onChange={(e: any) => {
                                    handleInputChangeArray(e, i)
                                  }}
                                  width={200}
                                />
                                &nbsp;&nbsp;
                                <Controls.WelloInput
                                  name='phone'
                                  label={
                                    formLabels?.phoneNumberLabel ??
                                    'PHONE NUMBER'
                                  }
                                  placeholder='Phone'
                                  type='number'
                                  required={true}
                                  // value={practitionerList[i].phone}
                                  error={errors.phone}
                                  width={200}
                                  onChange={(e: any) =>
                                    handleInputChangeArray(e, i)
                                  }
                                  charsAllowed={10}
                                />
                                &nbsp;&nbsp;
                                <Controls.WelloSelectForm
                                  name='role'
                                  required={true}
                                  label={
                                    formLabels?.roleLabel ?? 'ORGANIZATION ROLE'
                                  }
                                  type='Select Organization Role'
                                  value={values.role}
                                  options={getRole()}
                                  onChange={(e: any) =>
                                    handleInputChangeArray(e, i)
                                  }
                                  width={200}
                                  error={errors.role}
                                />
                                &nbsp;
                                {practitionerList.length !== 1 && (
                                  <React.Fragment>
                                    <Controls.WelloActionButton
                                      color='secondary'
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      <CloseIcon />
                                    </Controls.WelloActionButton>
                                    <br></br>
                                  </React.Fragment>
                                )}
                                {practitionerList.length - 1 === i &&
                                  practitionerList.length < 4 && (
                                    <div onClick={handleAddClick}>
                                      <Grid
                                        container
                                        direction='row'
                                        spacing={0}
                                      >
                                        <Grid item xs={3}>
                                          <Box>
                                            <Typography
                                              style={{
                                                backgroundColor: useTheme()
                                                  .palette.background.paper
                                              }}
                                            >
                                              + Add Another User &nbsp; &nbsp;
                                              &nbsp; &nbsp;{' '}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={9}>
                                          <Box style={{ marginTop: 15 }}>
                                            {' '}
                                            <Divider />{' '}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Divider />
          </form>
        </Paper>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#cdcdcd' }}>
        <Controls.WelloButton
          type='button'
          text={cancelButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
          id='cancel'
        />
        <Controls.WelloButton
          type='button'
          text={inviteButtonText ?? 'Invite'}
          size='small'
          variant='outlined'
          backgroundColor='#4543d9'
          color='#cdcdcd'
          onClick={handleSubmit}
          id='invite'
        />
      </DialogActions>
    </Dialog>
  )
}
