/* eslint-disable */
import React, { useState } from 'react'
import { Box, Grid, Typography, Paper, useTheme,makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  IconButton} from '@material-ui/core'
import ScheduleSelector from 'react-schedule-selector'
import moment from 'moment'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Controls } from '../PrmitiveComponent'
import Alert from '@material-ui/lab/Alert'
import { AlertTitle } from '@material-ui/lab'
import { getDaysOfWeek } from '../../utils/ServiceHelper/Services/ExpertService'
import {
  Form,
  UseForm
} from '../../components/PrmitiveComponent/component/Helper/UseForm'
import {
  getFhirBundleObjectFrom,
  getFhirScheduleObjectFromForm
} from '../../utils/formHelper'
import WelloTabs from '../PrmitiveComponent/component/WelloTabs'
import { TimeTabs } from  './TimeTabs'
import CloseIcon from "@material-ui/icons/Close";

const menu = ['15 min','30 min','1 hour']

interface IProps {
  submitButtonText?: string
  resetButtonText?: string
  doctorList: any[]
  onValidSubmit?: (
    Slot:string[],
    Schedule: R4.ISchedule,
    duration: number
  ) => void
  preSlot?:string[]
  preAddedSchedule?: R4.ISchedule
  onClickToCancel: (val: boolean) => void
  popupSate: boolean
}

const errorValues = {
}

function getInitializeValues(dateArr?: string[], schedule?: R4.ISchedule) {
   let initialFValues = {
     id:0,
     doctorId: '',
     scheduleData: [],
     days:['Mon','Tue','Wed','Thu','Fri'],
     time : 1
   }
   if (dateArr != null || schedule != null) {
     initialFValues = {
       id:0,
      doctorId: '',
      scheduleData: [],
      days:['Mon','Tue','Wed','Thu','Fri'],
      time:1
    }
   }
   return initialFValues
   }

   const useStyles = makeStyles((theme) => ({
    topHeader: {
      color: theme.palette.common.white,
      marginBottom: 5
    },
    dialogPaper: {
      minHeight: '100vh',
      maxHeight: '100vh',
  },

  }))

export const Schedular: React.FC<IProps> = ({
  submitButtonText,
  resetButtonText,
  doctorList,
  onClickToCancel,
  onValidSubmit,
  preSlot,
  preAddedSchedule,
  popupSate = false
}: IProps) => {
  const [scheduleDataReact, setScheduleData] = React.useState<Date[]>([])
  const [selectedTab, setSelectedTab] = React.useState(menu[2])
  const classes = useStyles()
  const initialFValues: any = getInitializeValues(
    preSlot,
    preAddedSchedule
  )
  const [openPopup, setOpenPopup] = useState(popupSate)

  const handleChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
    if(selectedTab === menu[0])
       values.time = 4
    else if(selectedTab === menu[1])
      values.time = 2
    else if(selectedTab === menu[2])
      values.time = 1
  }

  function validate(fieldValues = values) {
  }
  const {
    values,
    errors,
    setErrors,
    resetForm,
    handleInputChange,
  } = UseForm(initialFValues, errorValues, true, validate)


  const renderCustomDateHeader = (
    time: moment.MomentInput,
    selected: any,
    innerRef: React.LegacyRef<HTMLDivElement>
  ) => (
    <div ref={innerRef}>
      {selected ? (
        <Box
          width={142}
          style={{ textAlign: 'center', height: 24 }}
          borderColor='primary.main'
          border={2}
          bgcolor='primary.main'
        >
          <Typography variant='subtitle2' style={{ color: 'white' }}>
            {' '}
            {moment(time).format('h:mm A')}{' '}
          </Typography>
        </Box>
      ) : (
        <Box
          width={142}
          style={{ textAlign: 'center',height: 24 }}
          borderColor='primary.main'
          border={2}
        >
          <Typography variant='subtitle2' color='primary'>
            {' '}
            {moment(time).format('h:mm A')}{' '}
          </Typography>
        </Box>
      )}
    </div>
  )

  const renderTimeLabel = (time: any) => <div></div>
  const renderDateLabel = (date: moment.MomentInput) => (
    <div>
      <Typography
        variant='subtitle2'
        style={{
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
        color='primary'
      >
        {moment(date).format('dddd')}
      </Typography>
    </div>
  )

  function handleSubmit() {
    console.log('In validation')
    values.scheduleData=scheduleDataReact
    const schedule: R4.ISchedule | undefined = getFhirScheduleObjectFromForm(values)
    const dateData:
    | string[]
    | undefined = getFhirBundleObjectFrom(values)
    const duration: number = values.time
    if (schedule) {
       if (onValidSubmit)
        onValidSubmit(dateData,schedule,duration)
        popupSate = false
        setOpenPopup(popupSate)
     } else {
       console.log('validation error')
     }
  }

  function onRangeSelect(item: any) {
    console.log('checked')
    console.log(item)
    setScheduleData(item)
  }

  const cancel = () => {
    onClickToCancel(false)
    // resetForm()
  }
  const handleClose = () => {
    onClickToCancel(false)
    // resetForm()
  }

  return (
    <Dialog
    open={popupSate}
    maxWidth='md'
    fullWidth
    onClose={handleClose}
    disableBackdropClick
    classes={{ paper: classes.dialogPaper }}
  >
    <DialogTitle
      id='scroll-dialog-title'
      style={{
        backgroundColor: useTheme().palette.primary.main,
        color: useTheme().palette.common.white,
        alignContent: 'center',
        textAlign: 'center',
        height: 48
      }}
    >
      <Typography variant='h3' className={classes.topHeader}>
       Manage Schedule
      </Typography>
    </DialogTitle>
    <DialogContent
        dividers={true}
        style={{
          backgroundColor: useTheme().palette.background.default,
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0
        }}
      >
    <Grid container justify='center' spacing={0}>
    <Box width="80%" p={1}>
       {/* {doctorList !== undefined ? <Controls.WelloSelectDoctor
        name='doctorId'
        required={true}
        label='Expert'
        type='Select Expert'
        onChange={handleInputChange}
        value={doctorList[0].id}
        options={doctorList}
        width={240}
      />:''} */}
        <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Alert
        onClose={() => {}}
        severity='info'
        style={{ backgroundColor: "#ffffff" }}
      >
      <Typography variant="subtitle1">
        Draw over the time slots by pressing and holding your mouse and select
        availability schedule.
        </Typography>
      </Alert>
      <br></br>
      <Box flexGrow={1} flexDirection="column">
              <Grid direction="column">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                  >
                    <Grid item>
      <Controls.WelloSelect
        label='DAYS OF THE WEEK'
        availableOptions={getDaysOfWeek}
        required={true}
        width={210}
        type='DAYS OF THE WEEK'
        displayField='title'
        valueField='id'
        preSelectedOption={getDaysOfWeek[0]}
        onSelectionChanged={(value: any) => {}}
      />
       {/* <TimeTabs   preSelectedTab={selectedTab}
            onTabChange={(e: any) => handleChangeTab(e)}
            menu={menu}/> */}
      </Grid>
      <Grid item>
        <TimeTabs   preSelectedTab={selectedTab}
            onTabChange={(e: any) => handleChangeTab(e)}
            menu={menu}/>
      </Grid>
      </Grid>
      </Grid>
      </Grid>
      </Box>

      <br/>
      <ScheduleSelector
        selection={scheduleDataReact}
        onChange={onRangeSelect}
        minTime={9}
        maxTime={18}
        renderDateCell={renderCustomDateHeader}
        renderTimeLabel={renderTimeLabel}
        renderDateLabel={renderDateLabel}
        startDate={new Date(moment().weekday(1).toString())}
        numDays={5}
        hourlyChunks={values.time}
        columnGap='4px'
        rowGap='1px'
        selectionScheme='square'
      />
    </Box>
    </Grid>
    </DialogContent>
    <DialogActions style={{ backgroundColor: useTheme().palette.background.default }}>
        <Controls.WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
          id='cancel'
        />
        <Controls.WelloButton
          type='button'
          text={submitButtonText ?? 'Add Schedule (Alt+S)'}
          size='small'
          variant='outlined'
          backgroundColor='#4543d9'
          color='#fefefe'
          onClick={handleSubmit}
          id='add_schedule'
        />
      </DialogActions>
    </Dialog>
  )
}
