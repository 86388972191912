import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    TextField,
    Typography
  } from '@material-ui/core'
  import { Search } from '@material-ui/icons'
  import React, { useEffect, useState } from 'react'
  import tripleDot from '../../../assets/images/trippleDot.png'
  import { Controls } from './Controls'
  
  export interface MultiSelectWithSearchOptionProps {
    placeholderLabel?: string
    label?: string
    title: string
    availableOptions?: any[]
    displayField?: string
    valueField?: string
    preSelectedOptions?: any[]
    onOptionsClicked?: () => void
    onValuesChanged: (selectedSpecializations: any[]) => void
    listHeight?: any
    id?: string
    showTitle?: boolean
  }
  
  export const MultiSelectWithSearchSpl: React.FC<MultiSelectWithSearchOptionProps> =
    ({
      placeholderLabel,
      label,
      title,
      availableOptions: availableOptions,
      displayField,
      valueField,
      preSelectedOptions,
      onValuesChanged,
      onOptionsClicked,
      listHeight,
      id,
      showTitle
    }: MultiSelectWithSearchOptionProps) => {
      const [selectedOptions, setSelectedOptions] = useState(
        preSelectedOptions ?? []
      )
      const [searchTerm, setSearchTerm] = React.useState('')
      const [searchResults, setSearchResults] = React.useState([])
      React.useEffect(() => {
        const results = availableOptions.filter((item) => {
          if (displayField) {
            return (item[displayField] as string)
              .toLowerCase()
              .includes(searchTerm)
          } else {
            return (item as string).toLowerCase().includes(searchTerm)
          }
        })
        setSearchResults(results)
      }, [searchTerm])
  
      function onItemSelected(item: any) {
        console.log('checked')
        selectedOptions.push(item)
        onValuesChanged(selectedOptions)
        setSelectedOptions(selectedOptions.map((i) => i))
      }
  
      function isItemSelected(item: any): boolean {
        const index: number = selectedOptions.findIndex((i) => {
          if (valueField) {
            return item[valueField] === i[valueField]
          } else {
            return item === i
          }
        })
  
        if (index > -1) {
          return true
        }
        return false
      }
      function onItemDeSelected(item: any) {
        const tempValues = selectedOptions.map((i) => i)
  
        const index = tempValues.findIndex((i) => {
          if (valueField) {
            return item[valueField] == i[valueField]
          } else {
            return item === i
          }
        })
        tempValues.splice(index, 1)
        onValuesChanged(tempValues)
        setSelectedOptions(tempValues)
      }
  
      return (
        <Box display='flex' flexDirection='column'>
          <Box width='100%'>
            {showTitle && (
              <Box display='flex' flexDirection='row'>
                <Box flexGrow={1}>
                  <Typography variant='subtitle2'>
                    {title}{' '}
                    {selectedOptions.length === 0 ? (
                      ''
                    ) : (
                      <span>({selectedOptions.length})</span>
                    )}
                  </Typography>
                </Box>
                <Box>
                  {onOptionsClicked && (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => {
                        onOptionsClicked
                      }}
                    >
                      <img src={tripleDot} alt='options' height='20' width='20' />
                    </IconButton>
                  )}
                </Box>
              </Box>
            )}
            <Box width='100%'>
              <TextField
                fullWidth={true}
                variant='outlined'
                margin='dense'
                color='primary'
                type='search'
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
                id={id}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  )
                }}
                style={{ backgroundColor: 'white', borderRadius: 4 }}
                placeholder={placeholderLabel ?? 'Search'}
              ></TextField>
            </Box>
            <Box
              width='100%'
              flexShrink={1}
              maxHeight={listHeight}
              overflow='auto'
            >
              <List
                style={{
                  overflowY: 'auto',
                  overflowX: 'auto'
                }}
              >
                {searchResults &&
                  searchResults.map((item) => (
                    <ListItem
                      id={
                        valueField
                          ? (item[valueField] as string)
                          : (item as string)
                      }
                      key={valueField ? item[valueField] : item}
                      role={undefined}
                      dense
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Controls.WelloCheckbox
                        key={valueField ? item[valueField] : item}
                        name={displayField ? item[displayField] : item}
                        label={displayField ? item[displayField] : item}
                        id={
                          valueField
                            ? (item[valueField] as string)
                            : (item as string)
                        }
                        isChecked={isItemSelected(item)}
                        onChange={(value) => {
                          if (value) {
                            onItemSelected(item)
                          } else {
                            onItemDeSelected(item)
                          }
                        }}
                      ></Controls.WelloCheckbox>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Box>
        </Box>
      )
    }
  