import { Box, Typography } from '@material-ui/core'
import MuiPhoneInput, { MuiPhoneNumberProps } from 'material-ui-phone-number'
import React from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'
import dotIcon from '../../../assets/images/dot_icon.png'

export interface WelloPhoneNumberFieldProps {
  title: string,
  value:string,
  textProps: MuiPhoneNumberProps
}

export const WelloPhoneNumberField: React.FunctionComponent<WelloPhoneNumberFieldProps> = (
  props: WelloPhoneNumberFieldProps
) => (
  <Box display='flex' flexDirection='column' flexGrow={1}>
        <Typography
        color='primary'
        style={{
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{props.title} &nbsp;
        {props.value.length === 10 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
    <MuiPhoneInput
      variant='outlined'
      preferredCountries={['in']}
      {...props.textProps}
    />
  </Box>
)
