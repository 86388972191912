/* eslint-disable react/jsx-key */
import React from 'react'
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  useTheme
} from '@material-ui/core'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { NoAppointmentCard } from './NoAppointmentCard'
import { DoctorPatientCard } from './DoctorPatientCard'

interface IProps {
  patient?: R4.IPatient
  doctor?: R4.IPractitioner
  typeOfAppointment?: string
  time?: string
  color?: any
  cardSize: boolean | 'auto' | 3 | 12 | 7 | 4 | 1 | 2 | 5 | 6 | 8 | 9 | 10 | 11
  appointmentList: any[]
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  timeStyle: {
    color: useTheme().palette.text.disabled,
    fontSize: 10,
    fontWeight: 600,
    marginLeft: 5
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    backgroundColor: '#f4f4f4',
    marginLeft: 10
  },
  patientName: {
    color: useTheme().palette.common.black,
    fontSize: 14,
    fontWeight: 600
  },

  doctorStyle: {
    color: useTheme().palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 7
  },
  timeSlotStyle: {
    color: useTheme().palette.text.disabled,
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'left',
    marginLeft: 7
  },
  dividerColor: {
    color: useTheme().palette.primary.dark
  },
  leftColumn: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  }
}))

export const AppointmentCard: React.FC<IProps> = ({
  patient,
  doctor,
  typeOfAppointment,
  time,
  color,
  cardSize,
  appointmentList
}: IProps) => {
  const classes = useStyles()
  const timeInterVals = [
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1P M',
    '2 PM',
    '4 PM',
    '5 PM'
  ]

  return (
    <div className={classes.root}>
      <Grid container>
        {timeInterVals.map((value, i) => (
          <>
            <Grid
              item
              xs={12}
              classes={{
                root: classes.leftColumn
              }}
            >
              <Grid container direction='row' justify='flex-start'>
                <Grid container direction='row' justify='flex-start'>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className={classes.timeStyle}>
                      {' '}
                      {value.length == 4 ? (
                        <span> &nbsp; {value} </span>
                      ) : (
                        value
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={cardSize}>
                    <Divider
                      style={{ height: 2 }}
                      className={classes.dividerColor}
                    ></Divider>
                    {appointmentList.length === 0 ? (
                      <NoAppointmentCard height={160}></NoAppointmentCard>
                    ) : (
                      <>
                        <DoctorPatientCard />
                        <NoAppointmentCard height={80}></NoAppointmentCard>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  )
}
