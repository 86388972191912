import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  textField: {
    // width: 200
  },
  menu: {
    // width: 200
  }
}))
export interface IProps {
  name: any
  label: any
  type?: any
  placeholder?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options: any
  required: boolean
  size?: any
  [other: string]: any
}

export const WelloSelectBox: React.FC<IProps> = ({
  name,
  label,
  value,
  error,
  onChange,
  options,
  size,
  other
}: IProps) => {
  const classes = useStyles()
  return (
    <TextField
      select
      id={name}
      label={label}
      variant='outlined'
      name={name}
      value={value}
      required={false}
      onChange={onChange}
      SelectProps={{
        MenuProps: {
          className: classes.menu
        }
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
      margin='normal'
    >
      {options.map((option: any) => (
        <MenuItem key={option.id} value={option.id}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  )
}
