import React from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Paper,
  Typography,
  Avatar
} from '@material-ui/core'

import pankaj from '../../../assets/images/pankaj.jpg'

interface ICardProperties {
  patientHealthInfo?: string
  patientName?: string
  profileImage?: string
  class?: string
  gender?: string
  age?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 2
    },
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      width: 300,
      backgroundColor: '#ffffff'
    },
    image: {
      width: 128,
      height: 128,
      position: 'relative'
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'relative'
    },
    overlappimg: {
      height: 20,
      // width: 20,
      align: 'center'
    },
    inlay: {
      left: 32,
      top: -15,
      display: 'block',
      height: 24,
      width: 54,
      position: 'relative'
    },
    dotIcon: {
      height: 12,
      // width: 20,
      align: 'center'
    },
    starIcon: {
      marginLeft: 'auto'
    },
    space: {
      padding: 0
    },
    upperDot: {
      allVariants: {
        color: 'pink'
      }
    }
  })
)

export const PatientProfileCard: React.FC<ICardProperties> = ({
  patientHealthInfo = '',
  patientName = '',
  profileImage = '',
  age = '',
  gender = ''
}: ICardProperties) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={0}>
          <Grid item className={classes.space}>
            <Typography variant='subtitle2' className={classes.upperDot}>
              {' '}
              <img src='white.png' alt='' />
            </Typography>
          </Grid>

          <Grid item spacing={0}>
            {patientName === 'Adam' && (
              <Avatar
                className={classes.image}
                alt='Remy Sharp'
                src='manish.jpeg'
              />
            )}

            {patientName === 'Rupesh' && (
              <Avatar
                className={classes.image}
                alt='Remy Sharp'
                src='neeraj.jpg'
              />
            )}

            {patientName === 'Pankaj' && (
              <Avatar
                className={classes.image}
                alt='Remy Sharp'
                src='pankaj.jpg'
              />
            )}

            {patientName === 'Leena' && (
              <Avatar className={classes.image} alt='Remy Sharp' src={pankaj} />
            )}

            <Paper className={classes.inlay} variant='outlined'>
              {gender === 'male' && (
                <Typography variant='subtitle2' gutterBottom color='primary'>
                  <img className={classes.overlappimg} src='m.png' alt='' />{' '}
                  &nbsp;
                  {age}
                </Typography>
              )}

              {gender === 'female' && (
                <Typography variant='subtitle2' gutterBottom color='primary'>
                  <img className={classes.overlappimg} src='f.png' alt='' />{' '}
                  &nbsp;
                  {age}
                </Typography>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={0}>
              <Grid item xs>
                <br></br>
                <br></br>
                <Typography align='left' variant='h6' color='primary'>
                  &nbsp;&nbsp;{patientName}
                </Typography>
                <Typography
                  align='left'
                  variant='body2'
                  gutterBottom
                  color='textSecondary'
                >
                  &nbsp;&nbsp; Normal Health
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' className={classes.upperDot}>
                {' '}
                <img className={classes.dotIcon} src='red.png' alt='' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
