/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PatientDataGrid } from '../../Card/PatientDataGrid'
import { PatientListTopComponent } from '../../Card/PatientListTopComponent'
import { PatientTopFilter } from '../../Card/PatientTopFilter'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { DisplayTabs } from '../../Card/DisplayTabs'
import { PatientTableView } from '../../Card/PatientTableView'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { randomInt } from 'crypto'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1)
  },
  right: {
    marginLeft: 'auto'
  },
  root: {
    width: '100%'
  },

  formControl: {
    margin: theme.spacing(0)
  },
  paper: {
    backgroundColor: '#ececec',
    overflow: 'auto',
    maxHeight: 405,
    border: 0
  },

  cardPaper: {
    backgroundColor: '#ececec',
    overflow: 'auto',
    maxHeight: 405,
    border: 0,
    maxWidth: '100vw',
    minWidth: 1250
  },
  selectEmpty: {}
}))

export const PatientsList: React.FC = () => {
  const classes = useStyles()
  const [menuCount, selectMenuCount] = React.useState(0)
  const [filterValues, setFilterValues] = React.useState<string[]>([])
  const [appointments, SetAppointments] = React.useState<any[]>([])
  const [tableAppointments, SetTableAppointments] = React.useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = React.useState('Grid')

  const handleRecordCountChange = (count: number) => {
    selectMenuCount(count)
    setLoading(false)
  }

  const handleClickFilter = (filters: string[]) => {
    setFilterValues(filters)
  }

  const handleChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
  }

  useEffect(() => {
    const tempPatientList: React.SetStateAction<R4.IPatient[]> = []
    const patientListTemp: React.SetStateAction<R4.IPatient[]> = []

    const appListTemp = [
      {
        date: '2020-11-30',
        patientData: patientListTemp
      }
    ]
    SetAppointments(appListTemp)
    SetTableAppointments(tempPatientList)
  }, [])

  return (
    <div>
      <Grid container alignItems='center' className={classes.root}>
        <Grid>
          <PatientListTopComponent
            label='Patients'
            onSelectChange={(e) => handleRecordCountChange(e)}
            count={menuCount}
          />
        </Grid>
        <Grid className={classes.right}>
          <DisplayTabs
            preSelectedTab={selectedTab}
            onTabChange={(e) => handleChangeTab(e)}
          />
        </Grid>
      </Grid>

      <PatientTopFilter onClickFilter={(e) => handleClickFilter(e)} />

      {selectedTab === 'Grid' && (
        <div>
          {tableAppointments.length === 0 && (
            <Paper className={classes.cardPaper} elevation={0}>
              <Typography variant='h5' style={{ color: '#4f4ce3' }}>
                No Patient Available
              </Typography>
            </Paper>
          )}
          {tableAppointments.length !== 0 && (
            <PatientTableView patientData={tableAppointments} />
          )}
        </div>
      )}
      {selectedTab === 'Cards' && (
        <Paper className={classes.cardPaper} elevation={0}>
          {appointments.length === 0 && (
            <Typography variant='h6' style={{ color: '#4f4ce3' }}>
              No Patient Available
            </Typography>
          )}

          {appointments.map((value, i) => (
            <PatientDataGrid
              key={randomInt(123)}
              date={
                moment
                  .duration(
                    moment(appointments[i].date, 'YYYY-MM-DD').diff(
                      moment().startOf('day')
                    )
                  )
                  .asDays() === 1
                  ? 'Tomorrow'
                  : moment
                      .duration(
                        moment(appointments[i].date, 'YYYY-MM-DD').diff(
                          moment().startOf('day')
                        )
                      )
                      .asDays() === -1
                  ? 'Yesterday'
                  : moment
                      .duration(
                        moment(appointments[i].date, 'YYYY-MM-DD').diff(
                          moment().startOf('day')
                        )
                      )
                      .asDays() === 0
                  ? 'Today'
                  : moment(appointments[i].date).format('dddd, DD MMM')
              }
              patientData={appointments[i].patientData}
            />
          ))}
        </Paper>
      )}
    </div>
  )
}
