import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Avatar } from '@material-ui/core'

interface ICardProperties {
  patientHealthInfo?: string
  patientName?: string[]
  profileImage?: string
  class?: string
  gender?: string
  age?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 2
    },
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      width: 300,
      backgroundColor: '#ffffff'
    },
    image: {
      width: 128,
      height: 128,
      position: 'relative'
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'relative'
    },
    overlappimg: {
      height: 20,

      // width: 20,
      align: 'center',
      marginLeft: 5
    },
    inlay: {
      left: 32,
      top: -15,
      display: 'block',
      height: 24,
      width: 54,
      position: 'relative'
    },
    dotIcon: {
      height: 12,
      // width: 20,
      align: 'center'
    },
    starIcon: {
      marginLeft: 'auto'
    },
    space: {
      padding: 0
    },
    age: {
      marginBottom: 10
    },
    upperDot: {
      allVariants: {
        color: 'pink'
      }
    }
  })
)

export const WelloPatientAvatarCard: React.FC<ICardProperties> = ({
  patientHealthInfo = '',
  patientName = [],
  profileImage = '',
  age = '',

  gender = ''
}: ICardProperties) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={0}>
          <Grid item className={classes.space}>
            <Typography variant='subtitle2' className={classes.upperDot}>
              {' '}
              <img src={profileImage} alt='' />
            </Typography>
          </Grid>

          <Grid item spacing={0}>
            <Avatar
              className={classes.image}
              alt='Remy Sharp'
              src='noImage.png'
            />

            <Paper className={classes.inlay} variant='outlined'>
              {gender === 'male' && (
                <Typography
                  className={classes.age}
                  gutterBottom
                  color='primary'
                >
                  <img className={classes.overlappimg} src='m.png' alt='' />{' '}
                  &nbsp;
                  {age}
                </Typography>
              )}

              {gender === 'female' && (
                <Typography
                  className={classes.age}
                  gutterBottom
                  color='primary'
                >
                  <img className={classes.overlappimg} src='f.png' alt='' />{' '}
                  &nbsp;
                  {age}
                </Typography>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={0}>
              <Grid item xs>
                <br></br>
                <br></br>
                <Typography align='left' variant='h6' color='primary'>
                  {patientName}
                </Typography>
                <Typography
                  align='left'
                  variant='body2'
                  gutterBottom
                  color='textSecondary'
                >
                  {patientHealthInfo ?? ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' className={classes.upperDot}>
                {' '}
                <img className={classes.dotIcon} src='red.png' alt='' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
