/* eslint-disable prefer-const */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export function UseForm(
  initialFValues: any,
  errorValues: any,
  validateOnChange = false,
  validate: (arg0: { [x: number]: any }) => void,
  index?: any
) {
  const [values, setValues] = useState(initialFValues)
  const [errors, setErrors] = useState(errorValues)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const handleInputChangeForPhoto = (nameVal:string,data:string) => {
    // const { name, value } = e.target

    setValues({
      ...values,
      [nameVal]: data
    })
    if (validateOnChange) validate({ [nameVal]: data })
  }




  const handlePhoneChange = (e: any) => {
  console.log(e)
    // const { name, value } = e.target
    // alert(name)
    // alert(value)

    // setValues({
    //   ...values,
    //   [name]: value
    // })
    // if (validateOnChange) validate({ [name]: value })
  }

  const handleDateChange = (e: any) => {
    const { name, value } = e.target
    let age = moment(value).format('DD-MM-YYYY')
    let ageData = 0
    let m = moment(age, 'DD.MM.YYYY')
    let years = moment().diff(m, 'years', false)
    ageData = years
    if (years === 0) {
      let months = moment().diff(m, 'years', false)
      ageData = months
    }
    setValues({
      ...values,
      age: ageData,
      [name]: value
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const handleChangeMultiple = (event: any) => {
    const { name } = event.target
    setValues({
      ...values,
      [name]: event.target.value
    })
  }

  const uploadPic = (e: any) => {
    const { name, value } = e.target
    let img: any
    console.log(e.target)
    if (e.target.files[0].size / 1024 <= 60) {
      let file = e.target.files[0]
      let reader = new FileReader()
      reader.onloadend = function () {
        console.log('RESULT', reader.result)
        if (reader.result !== null) {
          img = reader.result
          setValues({
            ...values,
            imgName: img.split(',')[1],
            // width: 350,
            type: file.type,
            [name]: reader.result
          })
          if (validateOnChange) validate({ [name]: file })
        }
      }
      reader.readAsDataURL(file)
    } else {
      alert('Please choose file less than 60 KB')
    }
  }

  const handleStartDateChangeForSchedule = (e: any) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
      strtDate: new Date(value).toISOString()
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const handleEndDateChangeForSchedule = (e: any) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
      endDate: new Date(value).toISOString()
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const handleStartTimeChangeForSchedule = (e: any) => {
    const { name, value } = e.target
    let date = moment().format('YYYY-MM-DD')
    let maxDate = new Date(date + 'T' + value).toISOString()
    setValues({
      ...values,
      [name]: value,
      startTime: maxDate
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const handleEndTimeChangeForSchedule = (e: any) => {
    const { name, value } = e.target
    let date = moment().format('YYYY-MM-DD')
    let maxDate = new Date(date + 'T' + value).toISOString()
    setValues({
      ...values,
      [name]: value,
      endTime: maxDate
    })
    if (validateOnChange) validate({ [name]: value })
  }

  const resetForm = () => {
    setValues(initialFValues)
    setErrors(initialFValues)
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    uploadPic,
    handleChangeMultiple,
    handleDateChange,
    handleStartDateChangeForSchedule,
    handleStartTimeChangeForSchedule,
    handleEndTimeChangeForSchedule,
    handleEndDateChangeForSchedule,
    handlePhoneChange,
    handleInputChangeForPhoto
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  TopPaper: {
    maxWidth: 960,
    height: 48,
    backgroundColor: theme.palette.primary.main
  },

  topHeader: {
    paddingTop: theme.spacing(1),
    color: theme.palette.common.white
  }
}))

export function Form(props: {
  [x: string]: any
  children: any
  title?: string
}) {
  const classes = useStyles()
  const { children, ...other } = props
  return (
    <form autoComplete='off' {...other} noValidate>
      <div className={classes.root}></div>
      {props.children}
    </form>
  )
}
