import React from 'react'
import { TextField, FormControl, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import dotIcon from '../../../assets/images/dot_icon.png'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    height: 2
  }
}))

export interface IProps {
  name: string
  label?: string
  type?: 'text' | 'number' | 'date' | 'email' | 'tel'
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  width?: number
}

export const PhoneNumber: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  width,
  required = false
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl {...(error && { error: true })} fullWidth>
       <Box py={1} px={0.25} display='flex' flexDirection='row'>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label} &nbsp;
        {value.length === 10 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      </Box>
      <TextField
        underlineShow={false}
        id={name}
        variant='outlined'
        name={name}
        value={value}
        fullWidth
        color='primary'
        type={type}
        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
        required={required}
        size={size}
        // className={classes.inputBoxStyle}
        placeholder={placeholder}
        // InputProps={{ classes: { input: classes.inputBoxStyle,width:10  }}}
        InputProps={{
          inputProps: {
            min: '1',
            pattern: '^[1-9]{1}[0-9]{9}$',
            maxLength: 10
          },
          style: {
            borderRadius: 6,
            color: useTheme().palette.text.primary,
            width: width,
            backgroundColor: '#ffffff',
            '&::placeholder': {
              color: useTheme().palette.text.primary
            },
            '&::input': {}
          },
          startAdornment: (
            <InputAdornment position='start'>
              <Box display='flex'>
              <img
                src='https://image.similarpng.com/very-thumbnail/2020/06/India-flag-icon-on-transparent-background-PNG.png'
                alt='img'
                height='20'
                width='30'
              />
              <Box paddingLeft={0.5} paddingY={0.5}>
               +91
               </Box>
             </Box>
            </InputAdornment>
          )
        }}
        onChange={onChange}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
