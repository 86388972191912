import _ from 'lodash'
import moment from 'moment'
import isObject from './isObject'

export type ConditionDefinition = {
  dependentAttribute: string
  operator: string
  value: string
}

export type ConditionElement = {
  action: string
  condition: ConditionDefinition
  value?: string
}

export function getOptionValue(option: any) {
  if (option === undefined) {
    return undefined
  }
  if (isObject(option)) {
    if (option.coding !== undefined) {
      if (Array.isArray(option.coding) && option.coding.length > 0) {
        if (
          option.coding[0].code !== undefined ||
          option.coding[0].cui !== undefined
        ) {
          return option.coding[0].code ?? option.coding[0].cui
        }
      }
    }

    if (option.value !== undefined) {
      return option.value
    }
  } else {
    return option
  }
}

export function getOptionByValue(options: any, value: string) {
  if (options === undefined) {
    return undefined
  }

  if (Array.isArray(options)) {
    const option = options.find((option) => {
      const values: string[] = getVariantValuesOfOption(option)

      return values.includes(value.trim())
    })

    return option
  } else {
    return options
  }
}

export function getOptionValueDe(option: any) {
  if (option === undefined) {
    return undefined
  }

  if (isObject(option)) {
    if (option.coding !== undefined) {
      if (Array.isArray(option.coding) && option.coding.length > 0) {
        if (
          option.coding[0].code !== undefined ||
          option.coding[0].cui !== undefined
        ) {
          return option.coding[0].code ?? option.coding[0].cui
        }
      }
    }

    if (option.value !== undefined) {
      return option.value
    }
  } else {
    return option
  }
}

export function getVariantValuesOfOption(option: any) {
  if (option === undefined) {
    return undefined
  }

  if (isObject(option)) {
    if (option.coding !== undefined) {
      if (Array.isArray(option.coding) && option.coding.length > 0) {
        const trimmedOptions: string[] = option.coding.map((coding: any) => {
          return (coding.code ?? coding.cui).trim()
        })

        return trimmedOptions
      }
    }

    if (option.value !== undefined) {
      return [option.value]
    }
  } else {
    return [option]
  }
}

export function getOptionPossibleValue(option: any) {
  if (option === undefined) {
    return []
  }
  if (isObject(option)) {
    if (option.coding !== undefined) {
      if (Array.isArray(option.coding) && option.coding.length > 0) {
        return option.coding.map((coding: any) => {
          return coding.code ?? coding.cui
        })
      }
    }

    if (option.value !== undefined) {
      return [option.value]
    }
  } else {
    return [option]
  }
}

export function getOptionLabel(option: any) {
  if (option === undefined) {
    return undefined
  }
  if (isObject(option)) {
    if (option.text !== undefined && option.text.length > 0) {
      return option.text
    }
    if (option.coding !== undefined) {
      if (Array.isArray(option.coding) && option.coding.length > 0) {
        if (option.coding[0].display !== undefined) {
          return option.coding[0].display
        }
      }
    }

    if (option.label !== undefined) {
      return option.label
    }
  } else {
    return option
  }
}

export function getOptionInformation(option: any) {
  if (option === undefined) {
    return undefined
  }
  if (isObject(option)) {
    if (option.coding !== undefined) {
      if (Array.isArray(option.coding) && option.coding.length > 0) {
        if (option.coding[0].information !== undefined) {
          return option.coding[0].information
        }
      }
    }

    if (option.information !== undefined) {
      return option.information
    }
  } else {
    return option
  }
}

export function getOptionObjectByCode(options: any[], code: string) {
  if (options === undefined || options.length === 0) {
    return undefined
  }
  const option = options.find((option) => {
    return (getOptionPossibleValue(option) ?? []).includes(code)
  })
  return option
}

export function hasDisplayConditions(conditions: ConditionElement[]) {
  let res = false
  if (conditions !== undefined) {
    conditions.forEach((condition) => {
      if (condition.action === 'show') {
        res = true
      }
    })
  }
  return res
}

export function hasConditionalValueSet(conditions: ConditionElement[]) {
  let res = false
  if (conditions !== undefined) {
    conditions.forEach((condition) => {
      if (condition.action === 'setValue') {
        res = true
      }
    })
  }
  return res
}

// Todo need to handle the res ='nan' case in the future
export function getConditionalValue(
  conditions: ConditionElement[],
  formsInput: any,
  formSchema?: Schema,
  namePrefix?: string,
  nameIndex?: number
) {
  if (!hasConditionalValueSet(conditions)) {
    return undefined
  }
  let forms = formsInput
  if (
    namePrefix !== undefined &&
    nameIndex !== undefined &&
    formsInput[namePrefix]
  ) {
    if (
      formsInput[namePrefix] === undefined ||
      formsInput[namePrefix][nameIndex] === undefined
    ) {
      return false
    }
    forms = formsInput[namePrefix][nameIndex]
  }

  let res = 'nan'

  const setValueConditions = conditions.filter((condition) => {
    if (condition.action === 'setValue') {
      return true
    }
    return false
  })

  for (const condition of setValueConditions) {
    switch (condition.condition.dependentAttribute) {
      case 'valueCodeableConcept':
        if (
          forms[condition.condition.dependentAttribute] &&
          getOptionByValue(
            formSchema?.properties[condition.condition.dependentAttribute]
              .options,
            forms[condition.condition.dependentAttribute]
          ) &&
          getVariantValuesOfOption(
            getOptionByValue(
              formSchema?.properties[condition.condition.dependentAttribute]
                .options,
              forms[condition.condition.dependentAttribute]
            )
          )
        ) {
          if (condition.condition.operator === '==') {
            if (
              getVariantValuesOfOption(
                getOptionByValue(
                  formSchema?.properties[condition.condition.dependentAttribute]
                    .options,
                  forms[condition.condition.dependentAttribute]
                )
              ).includes(condition.condition.value)
            ) {
              res = condition.value
            }
          }
          if (condition.condition.operator === '!=') {
            if (
              !getVariantValuesOfOption(
                getOptionByValue(
                  formSchema?.properties[condition.condition.dependentAttribute]
                    .options,
                  forms[condition.condition.dependentAttribute]
                )
              ).includes(condition.condition.value)
            ) {
              res = condition.value
            }
          }
        }
        break
      case 'valueBoolean':
        {
          console.log(
            '---------valueQuantity----------',
            forms[condition.condition.dependentAttribute]
          )
          console.log('form', forms)
          console.log('condition', condition)
          console.log('condition', condition.condition)
          if (forms[condition.condition.dependentAttribute]) {
            switch (condition.condition.operator) {
              case '==':
                if (
                  forms[condition.condition.dependentAttribute] ===
                  condition.condition.value
                )
                  res = condition.value
                break
              case '!=':
                if (
                  forms[condition.condition.dependentAttribute] !==
                  condition.condition.value
                )
                  res = condition.value
                break
              default:
                break
            }
          }
        }
        break
      case 'valueQuantity':
        {
          if (forms[condition.condition.dependentAttribute]) {
            let currentConditionRes = undefined

            switch (condition.condition.operator) {
              case '==':
                if (
                  forms[condition.condition.dependentAttribute] ===
                  _.toNumber(condition.condition.value)
                )
                  currentConditionRes = condition.value
                break
              case '!=':
                if (
                  forms[condition.condition.dependentAttribute] !==
                  _.toNumber(condition.condition.value)
                )
                  currentConditionRes = condition.value
                break

              case '>':
                if (
                  forms[condition.condition.dependentAttribute] >
                  _.toNumber(condition.condition.value)
                )
                  currentConditionRes = condition.value
                break
              case '>=':
                if (
                  forms[condition.condition.dependentAttribute] >=
                  _.toNumber(condition.condition.value)
                )
                  currentConditionRes = condition.value
                break
              case '<':
                if (
                  forms[condition.condition.dependentAttribute] <
                  _.toNumber(condition.condition.value)
                )
                  currentConditionRes = condition.value
                break
              case '<=':
                if (
                  forms[condition.condition.dependentAttribute] <=
                  _.toNumber(condition.condition.value)
                )
                  currentConditionRes = condition.value
                break
            }

            if (currentConditionRes !== undefined) {
              res = currentConditionRes
              break
            }
          }
        }
        break

      default:
        break
    }

    console.log('valueQuantity', forms[condition.condition.dependentAttribute])
    console.log('form', forms)
    console.log('each condition', condition)
    console.log('each condition.condition', condition.condition)

    console.log('---------------each condition res---------------------', res)
    if (res !== undefined && res !== 'nan') {
      break
    }
  }

  console.log('---------------res---------------------', res)

  return res
}

export function displayConditionSatisfied(
  conditions: ConditionElement[],
  formsInput: any,
  formSchema?: Schema,
  namePrefix?: string,
  nameIndex?: number
) {
  let forms = formsInput
  if (namePrefix === undefined) {
    console.log('condition', conditions)
    console.log('form', forms)
  }
  if (
    formsInput !== undefined &&
    formsInput[namePrefix] !== undefined &&
    formsInput[namePrefix][nameIndex] !== undefined
  ) {
    if (
      namePrefix !== undefined &&
      nameIndex !== undefined &&
      formsInput[namePrefix]
    ) {
      if (
        formsInput[namePrefix] === undefined ||
        formsInput[namePrefix][nameIndex] === undefined
      ) {
        return false
      }
      forms = formsInput[namePrefix][nameIndex]
    }
  }
  let res = false
  if (forms) {
    const displayConditions = conditions.filter((condition) => {
      if (condition.action === 'show') {
        return true
      }
    })
    if (displayConditions.length === 0) {
      res = true
    }

    displayConditions.forEach((condition) => {
      switch (condition.condition.dependentAttribute) {
        case 'valueCodeableConcept':
          if (
            forms[condition.condition.dependentAttribute] &&
            getOptionByValue(
              formSchema?.properties[condition.condition.dependentAttribute]
                .options,
              forms[condition.condition.dependentAttribute]
            ) &&
            getVariantValuesOfOption(
              getOptionByValue(
                formSchema?.properties[condition.condition.dependentAttribute]
                  .options,
                forms[condition.condition.dependentAttribute]
              )
            )
          ) {
            if (condition.condition.operator === '==') {
              if (
                getVariantValuesOfOption(
                  getOptionByValue(
                    formSchema?.properties[
                      condition.condition.dependentAttribute
                    ].options,
                    forms[condition.condition.dependentAttribute]
                  )
                ).includes(condition.condition.value)
              ) {
                res = res || true
              }
            }
            if (condition.condition.operator === '!=') {
              if (
                !getVariantValuesOfOption(
                  getOptionByValue(
                    formSchema?.properties[
                      condition.condition.dependentAttribute
                    ].options,
                    forms[condition.condition.dependentAttribute]
                  )
                ).includes(condition.condition.value)
              ) {
                res = res || true
              }
            }
          }
          break
        case 'valueBoolean':
          {
            if (forms[condition.condition.dependentAttribute]) {
              switch (condition.condition.operator) {
                case '==':
                  if (
                    forms[condition.condition.dependentAttribute] ===
                    condition.condition.value
                  )
                    res = res || true
                  break
                case '!=':
                  if (
                    forms[condition.condition.dependentAttribute] !==
                    condition.condition.value
                  )
                    res = res || true
                  break
                default:
                  break
              }
            }
          }
          break
        case 'valueQuantity':
          {
            if (forms[condition.condition.dependentAttribute]) {
              switch (condition.condition.operator) {
                case '==':
                  if (
                    forms[condition.condition.dependentAttribute] ===
                    condition.condition.value
                  )
                    res = res || true
                  break
                case '!=':
                  if (
                    forms[condition.condition.dependentAttribute] !==
                    condition.condition.value
                  )
                    res = res || true
                  break

                case '>':
                  if (
                    forms[condition.condition.dependentAttribute] >
                    condition.condition.value
                  )
                    res = res || true
                  break
                case '>=':
                  if (
                    forms[condition.condition.dependentAttribute] >=
                    condition.condition.value
                  )
                    res = res || true
                  break
                case '<':
                  if (
                    forms[condition.condition.dependentAttribute] <
                    condition.condition.value
                  )
                    res = res || true
                  break
                case '<=':
                  if (
                    forms[condition.condition.dependentAttribute] <=
                    condition.condition.value
                  )
                    res = res || true
                  break
              }
            }
          }
          break

        default:
          break
      }
    })
  }
  return res
}

export function getProperties(inputJson: any) {
  const data: SchemaProps = {}
  inputJson.fields.forEach((field: any) => {
    if (field.type === 'forms') {
      const forms = []
      field.subForms.forEach((subForm: any) => {
        forms.push(getProperties(subForm))
      })
      data[field.name] = { ...field, forms: forms }
    } else {
      data[field.name] = field
    }
  })

  const schema: Schema = {
    title: inputJson.title,
    description: inputJson.description,
    properties: data,
    type: inputJson.type,
    require: inputJson.required,
    process: '',
    uuid: inputJson['form-uuid'],
    name: inputJson.title,
    section: inputJson.section,
    sectionIndex: inputJson.sectionIndex,
    formIndex: inputJson.index,
    subSection: inputJson.subSectionsName,
    subSectionIndex: inputJson.subSectionsIndex,
    category: inputJson.category,
    categoryIndex: inputJson.categoryIndex,
    formRenderType: inputJson.formTemplateCategory,
    ...inputJson
  }

  return schema
}

export function convertFormOutputWithSchema(formOutput: any, schema: Schema) {
  const output: any = {}
  Object.keys(schema.properties).forEach((key) => {
    const field = schema.properties[key]

    if (formOutput[key] == 'nan') {
      formOutput[key] = undefined
    }

    if (field.type === 'forms') {
      const forms = []
      field.forms.forEach((subForm: Schema, index) => {
        if (
          formOutput[key] !== undefined &&
          formOutput[key][index] !== undefined
        ) {
          const res = convertFormOutputWithSchema(
            formOutput[key][index],
            subForm
          )

          if (
            res &&
            res['valueCodeableConcept'] &&
            Array.isArray(res['valueCodeableConcept'])
          ) {
            if (res['valueCodeableConcept'].length > 0) {
              res['valueCodeableConcept'].forEach((item: any) => {
                const newForm = {
                  ...res,
                  valueCodeableConcept: item
                }
                forms.push(newForm)
              })
            } else {
              forms.push({
                ...res,
                valueCodeableConcept: undefined
              })
            }
          } else {
            forms.push(res)
          }
        }
      })
      output[key] = forms
    } else if (formOutput[key] != undefined) {
      if (field.type === 'string' && formOutput[key].length > 0) {
        output[key] = formOutput[key]
      }
      if (field.options) {
        if (Array.isArray(field.options)) {
          if (field.type === 'checkbox' && Array.isArray(formOutput[key])) {
            const selectedValues = formOutput[key]

            output[key] = selectedValues.map((option: any) => {
              return getOptionObjectByCode(field.options, option)
            })
          } else if (field.name === 'interpretation') {
            output[key] = [
              getOptionObjectByCode(field.options, formOutput[key])
            ]
          } else if (field.name === 'category') {
            output[key] = [
              getOptionObjectByCode(field.options, formOutput[key])
            ]
          } else {
            output[key] = getOptionObjectByCode(field.options, formOutput[key])
          }
        } else {
          output[key] = field.options
        }
      } else if (
        field.type === 'boolean' &&
        formOutput[key] !== undefined &&
        formOutput[key].length > 0
      ) {
        output[key] = formOutput[key] === 'true' ? true : false
      } else if (
        field.name === 'valueQuantity' &&
        formOutput[key] !== undefined
      ) {
        // TODO: handle unit more proper way
        output[key] = {
          value: formOutput[key],
          ...field.unit
        }
      } else if (
        field.name === 'valueDateTime' &&
        formOutput[key] !== undefined &&
        formOutput[key].length > 0 &&
        moment(formOutput[key]).isValid()
      ) {
        output[key] = moment(formOutput[key]).utc().format().toString()
      } else {
        console.log(key, formOutput[key])
        output[key] = formOutput[key]
      }
    } else {
      // output[key] = undefined
    }
  })

  console.log('--------output---------', output)

  return output
}
