import React, { useState } from 'react'
import { makeStyles, Grid, Paper } from '@material-ui/core'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import AccountCircle from '@material-ui/icons/AccountCircle'
import VideocamIcon from '@material-ui/icons/Videocam'
import Typography from '@material-ui/core/Typography'
import PublishIcon from '@material-ui/icons/Publish'

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  buttonText?: string
  onClickContinue: () => void
}

export const GenderList = () => [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
  { id: 'unknown', title: 'Unknown' }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    width: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    overflow: 'Auto'
  },

  TopPaper: {
    width: 900,
    height: 48,
    backgroundColor: '#4543d9'
  },

  topHeader: {
    fontSize: 16,
    display: 'inline'
  },
  buttonStyle: {
    fontSize: 14,
    display: 'inline',
    marginTop: 50
  },
  box: {
    marginBottom: 10,
    display: 'inline-block'
  },
  ml10: {
    marginLeft: 10
  },

  distance: {
    marginTop: 10
  },

  footer: {
    marginTop: 33.5
  },

  addMore: {
    backgroundColor: theme.palette.background.paper
  },

  specialOutline: {
    borderColor: 'grey',
    borderWidth: 1,
    width: 470
  },

  cameraContianer: {
    color: '#333333',
    fontSize: 10,
    marginLeft: 285
  },
  contactInfoHeader: {
    marginTop: 20,
    color: '#4d4d4d',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 260
  },
  inputBoxStyle: {
    borderRadius: 6,
    height: 2,
    width: 135,
    boxShadow: 'inset 0 0 3 0 rgba(0, 0, 0, 0.41)',
    backgroundColor: '#ffffff'
  }
}))

export const FinishLabSetup: React.FC<IUnitAdmin> = ({
  children,
  count,
  buttonText,
  onClickContinue
}: IUnitAdmin) => {
  const classes = useStyles()
  const [phone, SetPhone] = useState('')
  const [gender, SetGender] = useState('')

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={0}>
          <Grid key={1} item>
            <Paper className={classes.mainPage}>
              {children}
              <div className={classes.distance}></div>
              <div className={classes.distance}></div>
              <br></br>
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    &nbsp; &nbsp;&nbsp;
                    <Controls.WelloInputFile
                      style={{ display: 'none' }}
                      name='logo'
                      label='PROFILE PIC'
                      icon={<AccountCircle />}
                      required={true}
                      accept='image/x-png,image/gif,image/jpeg'
                      fileType='Select file'
                    />
                    &nbsp; &nbsp;
                    <Controls.WelloInput
                      name='name'
                      required={true}
                      label='NAME'
                      type='text'
                      placeholder='Name'
                      width={310}
                      charsAllowed={30}
                    />
                    &nbsp; &nbsp;
                  </Grid>
                </Grid>

                <br></br>
                <Grid item xs={12}>
                  <div className={classes.distance}></div>
                  <Grid container justify='center'>
                    <Controls.WelloEmailInput
                      name='email'
                      label='EMAIL ADDRESS'
                      placeholder='Email Address'
                      type='email'
                      required={true}
                      width={200}
                    />
                    &nbsp; &nbsp;
                    <Controls.PhoneNumber
                      name='phone'
                      label='PHONE NUMBER'
                      placeholder='Phone Number'
                      type='number'
                      required={true}
                      value={phone}
                      width={200}
                      // classNme={classes.ml10}
                    />
                  </Grid>
                </Grid>

                <br></br>
                <Grid item xs={12}>
                  <div className={classes.distance}></div>
                  <Grid container justify='center'>
                    <Controls.WelloDate
                      name='dob'
                      label='DATE OF BIRTH'
                      placeholder='dd/mm/yyyy'
                      required={true}
                      width={200}
                    />
                    &nbsp; &nbsp;
                    <Controls.WelloSelectGender
                      name='gender'
                      required={true}
                      label='GENDER'
                      type='Gender'
                      width={200}
                    />
                  </Grid>
                  <div className={classes.distance}></div>
                  <Grid container justify='center'>
                    {/* <div className={classes.distance}></div> */}
                    <Controls.WelloInput
                      name='address'
                      required={true}
                      label='YOUR CURRENT HOME ADDRESS'
                      type='text'
                      placeholder='Your Home Address'
                      width={410}
                      charsAllowed={100}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <Grid container justify='center'>
                    <Controls.WelloInputFile
                      style={{ display: 'none' }}
                      name='profilePic'
                      label='TAKE A SELFIE'
                      icon={<VideocamIcon />}
                      required={true}
                      accept='image/x-png,image/gif,image/jpeg'
                      fileType='Open Device Camera'
                    />
                  </Grid>

                  <Grid container justify='center'>
                    <div className={classes.distance}></div>
                    <Typography className={classes.cameraContianer}>
                      Must show your face clearly in a well lit environment just
                      how you would take a passport photo. This will only be
                      used for identity verification purposes.
                    </Typography>
                  </Grid>

                  <div className={classes.distance}></div>
                  <div className={classes.distance}></div>
                  <Typography className={classes.contactInfoHeader}>
                    &nbsp;Upload Documents{' '}
                    <div className={classes.distance}></div>
                  </Typography>

                  <Grid container>
                    <div style={{ marginLeft: 280, marginTop: 10 }}>
                      <Controls.WelloInputFile
                        style={{ display: 'none' }}
                        name='idProff'
                        label='IDENTITY PROOF'
                        icon={<PublishIcon />}
                        required={true}
                        accept='image/x-png,image/gif,image/jpeg'
                        fileType='Select file'
                      />
                    </div>
                    <div className={classes.distance}></div>
                    <Typography className={classes.cameraContianer}>
                      Must show your Photo, Name, Date of Birth and Current Home
                      Address. Such as Driver’s License, Ration Card etc.
                    </Typography>
                  </Grid>
                  <Grid container>
                    <div style={{ marginLeft: 280, marginTop: 10 }}>
                      <Controls.WelloInputFile
                        style={{ display: 'none' }}
                        name='addressProof'
                        label='ADDRESS PROOF'
                        icon={<PublishIcon />}
                        required={true}
                        accept='image/x-png,image/gif,image/jpeg'
                        fileType='Select file'
                      />
                    </div>
                    <div className={classes.distance}></div>
                    <Typography className={classes.cameraContianer}>
                      Must show your Name and Current Home Address. Such as Bank
                      Statement, Utility Bill, Phone Bill etc.
                    </Typography>
                    <div style={{ marginLeft: 280, marginTop: 10 }}>
                      {/*  <Controls.WelloCheckbox
                        label='My Identity document shows my Current Home  Address'
                        name='homeAddress'
                      /> */}
                    </div>
                  </Grid>

                  <Grid container>
                    <div style={{ marginLeft: 280, marginTop: 10 }}>
                      <Controls.WelloInputFile
                        style={{ display: 'none' }}
                        name='addressProof'
                        label='BACKGROUND CHECK OR POLICE VERIFICATION REPORT'
                        icon={<PublishIcon />}
                        required={true}
                        accept='image/x-png,image/gif,image/jpeg'
                        fileType='Select file'
                      />
                    </div>
                    <div className={classes.distance}></div>
                    <Typography className={classes.cameraContianer}>
                      Must show your Photo, Name, Date of Birth and Current Home
                      Address. Such as Driver’s License, Ration Card etc.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footer}></div>
              <div className={classes.distance}></div>
              <Grid container justify='flex-end'>
                <div className={classes.buttonStyle}>
                  <Controls.WelloButton
                    type='submit'
                    text={buttonText ?? 'Complete Setup'}
                    size='small'
                    backgroundColor='#4543d9'
                    color='#ffffff'
                    onClick={onClickContinue}
                  />
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
