const KEYS = {
  experts: '',
  expertsId: 'expertsId'
}
export const getDaysOfWeek = [{ id: 'weekDays', title: 'WeekDays' }]

export const getTitle = () => [
  { id: 'Dr.', title: 'Dr' },
  { id: 'Mr.', title: 'Mr' },
  { id: 'Mrs.', title: 'Mrs' },
  { id: 'Ms.', title: 'Ms' },
  { id: 'Miss', title: 'Miss' }
]

export const martialList = () => [
  { id: 'D', title: 'Divorced' },
  { id: 'M', title: 'Married' },
  { id: 'U', title: 'Single' },
  { id: 'W', title: 'Widowed' },
 
]

export const getActivityType = () => [
  { id: '1', title: 'Eat' },
  { id: '2', title: 'Move' },
  { id: '3', title: 'Medicate' }
]

export const getRole = () => [{ id: 'doctor', title: 'Doctor' }]

export const languagesList = () => [
  'Hindi',
  'English',
  'Kannada',
  'Telgu',
  'Tamil',
  'Malyalam',
  'Marathi',
  'Gujarati',
  'Bengali',
  'Oriya',
  'Assamese'
]

export const languages = [
  'Hindi',
  'English',
  'Kannada',
  'Telgu',
  'Tamil',
  'Malyalam',
  'Marathi',
  'Gujarati',
  'Bengali',
  'Oriya',
  'Assamese'
]
export const splData = [
  'Naturopathy',
  'Yoga Therapy',
  'Ayurveda',
  'Acupuncture',
  'Allergy/Immunology',
  'Anesthesiology',
  'Audiologist',
  'Cardiac Electrophysiology',
  'Cardiology',
  'Cardiothoracic Surgeon',
  'Colorectal Surgeon',
  'Cosmetic Surgeon',
  'Diabetology',
  'Dermatology',
  'Developmental Pediatrics',
  'Ear Nose Throat (ENT)',
  'Embryology Service',
  'Emergency Medicine Clinic',
  'Endocrinology',
  'Endodontist',
  'Epidemiology',
  'Family Practitioner',
  'Fitness Center',
  'Gastroenterology',
  'Genetics Gym',
  'General Surgery',
  'Hand Surgery',
  'Hematology',
  'Homeopathy',
  'Infectious Disease',
  'Integrated Medicine',
  'Infertility Clinic',
  'Internist',
  'Laboratory Medicine',
  'Nephrology',
  'Neurosurgery',
  'Nuclear Medicine',
  'Nutrition,Obstetrics/Gynaecology',
  'Occupational Therapy',
  'Oncology',
  'Ophthalmology',
  'Oral Surgery',
  'Orthodontics',
  'Orthopedics Surgery',
  'Pain Management',
  'Pathology',
  'Pediatric Dentistry',
  'Pediatric Surgeon',
  'Pediatrics',
  'Periodontistry',
  'Physical Medicine and Rehab',
  'Physical Therapy',
  'Physiotherapist',
  'Plastic Surgery',
  'Podiatry,Polyclinic',
  'Pranic Healing',
  'Primary Care',
  'Prosthodontics',
  'Psychiatry',
  'Psychology',
  'Public Health',
  'Pulmonology',
  'Radiation Oncology',
  'Radiology',
  'Rheumatology',
  'Sexual Medicine/Sexology',
  'Siddha',
  'Sleep Disorders',
  'Spa and Salon',
  'Speech Therapy',
  'Sports Medicine Specialist',
  'Trichology',
  'Urology',
  'Vascular Surgery',
  'Veterinary',
  'Wellness Center',
  'Yoga'
]

export const qualifications = [
  'BNYS',
  'MBBS',
  'MD',
  'MD, DM',
  'DNB',
  'MCH',
  'MRCP',
  'FRCP',
  'BAMS',
  'BDS',
  'BHMS',
  'NMD'
]

export function getAllCategroy() {
  if (localStorage.getItem(KEYS.experts) == null)
    localStorage.setItem(KEYS.experts, JSON.stringify([]))
  const employees = JSON.parse(localStorage.getItem(KEYS.experts) || '{}')
  //map departmentID to department title
  const departments = getTitle()
  return employees.map((x) => ({
    ...x,
    department: departments[x.departmentId - 1].title
  }))
}
