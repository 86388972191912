import { getProperties } from '../utils/fieldUtils'
import {
  SectionGroupedSchema,
  getFormsBySections1
} from '../utils/observationFormUtils'

interface FormRequestParams {
  ocaURL: string
  ocaSection: string
  ocaCategory: string
  ocaFileName?: string
  requestHeaders?: object
}

class Requestor {
  constructor() {}

  static schemaBaseOfCategory(
    ocaURL: string,
    headers: object
  ): Promise<SectionGroupedSchema[]> {
    return fetch(`${ocaURL}?isCategory=true`, {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then((json: any) => {
        let schemas: Schema[] = []
        // console.debug("json", json);
        if (json) {
          if (Array.isArray(json)) {
            schemas = json.map((item: any) => {
              const schema: Schema = getProperties(item)
              return schema
            })
          }
          console.debug('---------schema-----------', schemas)
          return getFormsBySections1(schemas)
        } else {
          console.warn('Data was not correct.', json)
          throw new Error('Data was not correct.')
        }
      })
      .catch((e) => {
        console.error(e)
        throw e
      })
  }

  /**
   * Fetches OCA forms from the server based on the provided request parameters.
   * @param requestParams - The request parameters for fetching OCA forms.
   * @returns A promise that resolves to an array of section-grouped schemas, a form configuration object, or undefined.
   * @throws An error if the response status is not ok or if the data is not correct.
   */
  static getOcaForms(
    requestParams: FormRequestParams
  ): Promise<SectionGroupedSchema[] | Schema | undefined> {
    let requestUrl = `${requestParams.ocaURL}/${requestParams.ocaSection}/${requestParams.ocaCategory}`
    if (requestParams.ocaFileName) {
      requestUrl = `${requestUrl}?formName=${encodeURIComponent(
        requestParams.ocaFileName
      )}&isCategory=false`
    } else {
      requestUrl = `${requestUrl}?isCategory=true`
    }

    return fetch(requestUrl, {
      method: 'GET',

      headers: {
        'Content-Type': 'application/json',
        ...requestParams.requestHeaders
      }
    })
      .then((response) => {
        console.debug('---------response-----------', response)
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then((json: any) => {
        let schemas: Schema[] = []
        console.log('json', json)
        if (json) {
          if (Array.isArray(json)) {
            schemas = json.map((item: any) => {
              const schema: Schema = getProperties(item)
              return schema
            })
            console.debug('---------schema-----------', schemas)
            return getFormsBySections1(schemas)
          } else {
            console.debug('---------schema-----------', schemas)
            const schema: Schema = getProperties(json)
            return schema
          }
        } else {
          console.warn('Data was not correct.', json)
          throw new Error('Data was not correct.')
        }
      })
      .catch((e) => {
        console.error(e)
        throw e
      })
  }

  static mapFormDataToMappingOverlay(
    mappingOverlayUrl: string,
    headers: object,
    formData: any
  ): Promise<any> {
    return fetch(`${mappingOverlayUrl}`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then((json: any) => {
        return json
      })
      .catch((e) => {
        throw e
      })
  }
}

export default Requestor
