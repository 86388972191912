import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
  withStyles,
  useTheme
} from '@material-ui/core'
import { Menu, MoreVert, Settings } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { SortIcon } from '../Card/SortIcon'
import maleIcon from '../../../assets/images/m.png'
import femaleIcon from '../../../assets/images/f.png'
import {
  getAgeOfPatient,
  getEmailOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient
} from '../../../utils/fhirHelpers/patientHelper'

export interface PatientDataWithAppointment {
  age: string
  gender: string
  patientName: string
  profilePic: string
  email: string
  fhirPatientDetail: R4.IPatient
  phone: string
}

interface ICardProperties {
  patientData: R4.IPatient[]
  onMenuPressed?: (contextPatient: R4.IPatient) => void
  onPatientSelected?: (selectedPatient: R4.IPatient) => void
  lastAppointmentDate?: string
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#dcdcdc'
    },
    column: {
      wordWrap: 'break-word',
      maxWidth: '20px'
    },
    body: {}
  })
)(TableCell)

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof PatientDataWithAppointment
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'patientName',
    numeric: false,
    disablePadding: false,
    label: 'Patient Name'
  },
  {
    id: 'age',
    numeric: false,
    disablePadding: false,
    label: 'Age'
  }
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PatientDataWithAppointment
  ) => void
  order: Order
  orderBy: string
  onSettingsClicked?: () => void
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, onSettingsClicked } = props
  const createSortHandler = (property: keyof PatientDataWithAppointment) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={SortIcon}
            >
              <Typography variant='subtitle1' color='primary'>
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell>
          <Typography variant='subtitle1' color='primary'>
            Gender
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1' color='primary'>
            Phone Number
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1' color='primary'>
            Email
          </Typography>
        </StyledTableCell>
        <StyledTableCell align='right'>
          {onSettingsClicked && (
            <IconButton
              color='primary'
              aria-label='upload picture'
              component='span'
              onClick={(e) => {
                onSettingsClicked()
              }}
            >
              <Settings color='primary'></Settings>
            </IconButton>
          )}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles({
  table: {},
  dotIcon: {
    height: 12
  },

  image: {
    width: 30,
    height: 30
  },
  overlappimg: {
    height: 20,
    // width: 20,
    align: 'center'
  },
  container: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.paper
      }
    }
  })
)(TableRow)

export const PatientTableView: React.FC<ICardProperties> = ({
  patientData,
  onMenuPressed,
  onPatientSelected,
  lastAppointmentDate
}: ICardProperties) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<
    keyof PatientDataWithAppointment
  >('patientName')
  const [dense, setDense] = React.useState(false)
  const [image, setImage] = React.useState('')

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PatientDataWithAppointment
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const convertedPatientData: PatientDataWithAppointment[] = patientData.map(
    (pat) => {
      const val: PatientDataWithAppointment = {
        patientName: getNameOfPatient(pat),
        age: getAgeOfPatient(pat).toString(),
        gender: pat.gender,
        fhirPatientDetail: pat,
        phone: getTelecomOfPatient(pat),
        profilePic: getProfilePicPatient(pat),
        email: getEmailOfPatient(pat)
      }
      return val
    }
  )

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label='sticky table'>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {convertedPatientData.map((row, i) => {
            return (
              <StyledTableRow key={i}>
                <StyledTableCell component='th' scope='row' align='right'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img className={classes.dotIcon} src='red.png' alt='' />
                    <Avatar
                      className={classes.image}
                      alt='Profile'
                      src={row.profilePic}
                    />
                    <Typography
                      variant='h6'
                      gutterBottom
                      color='primary'
                      style={{
                        padding: 4
                      }}
                    >
                      {row.patientName}
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell style={{ alignItems: 'center' }}>
                  <Typography
                    variant='h6'
                    gutterBottom
                    color='primary'
                    style={{
                      padding: 4
                    }}
                  >
                    {row.age} years
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: 'primary',
                    alignItems: 'center'
                  }}
                >
                  {row.gender === 'male' && (
                    <div style={{ display: 'inline-flex' }}>
                      <img
                        className={classes.overlappimg}
                        src={maleIcon}
                        alt=''
                      />
                      <Typography variant='h6' gutterBottom color='primary'>
                        &nbsp; {'Male'}
                      </Typography>
                    </div>
                  )}

                  {row.gender === 'female' && (
                    <div style={{ display: 'inline-flex' }}>
                      <img
                        className={classes.overlappimg}
                        src={femaleIcon}
                        alt=''
                      />{' '}
                      <Typography variant='h6' gutterBottom color='primary'>
                        &nbsp; {'Female'}
                      </Typography>
                    </div>
                  )}

                  {row.gender === 'other' && (
                    <Typography variant='h6' gutterBottom color='primary'>
                      &nbsp; {'Other'}
                    </Typography>
                  )}
                  {row.gender === 'unknown' && (
                    <Typography variant='h6' gutterBottom color='primary'>
                      &nbsp; {'Unknown'}
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  <Typography
                    align='left'
                    variant='h6'
                    gutterBottom
                    color='primary'
                  >
                    {row.phone}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align='right' color='primary'>
                  <Typography
                    align='left'
                    variant='h6'
                    gutterBottom
                    color='primary'
                  >
                    {row.email}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell align='right'>
                  {onMenuPressed && (
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                      onClick={(e) => {
                        onMenuPressed(row.fhirPatientDetail)
                      }}
                    >
                      <MoreVert color='primary' />
                    </IconButton>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
