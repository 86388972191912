import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    borderRadius: 6,
    // height: 7,
    maxWidth: 480,
    // width: 360,
    spellcheck: true,
    boxShadow: 'inset 0 0 3 0 rgba(0, 0, 0, 0.41)',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[50],
    fontSize: 13,
    '&::placeholder': {
      color: theme.palette.text.primary,
      fontSize: 13
    }
  },

  label: {
    color: theme.palette.text.primary
  }
}))

export interface IProps {
  name: string
  label: string
  type?: 'text' | 'number' | 'date' | 'email'
  placeholder?: any
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  [other: string]: any
}

const WelloInputTab: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  other
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl>
      <Typography className={classes.label} variant='subtitle2'>
        &nbsp;&nbsp;{label}
      </Typography>
      <TextField
        id={name}
        underlineShow={false}
        variant='outlined'
        name={name}
        placeholder={placeholder}
        value={value}
        color={useTheme().palette.primary.main}
        required
        size={size}
        InputProps={{ classes: { input: classes.inputBoxStyle } }}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
export default WelloInputTab
