import React from 'react'
import { makeStyles, Box, Grid, Typography, useTheme } from '@material-ui/core'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}
const useStyles = makeStyles((theme) => ({
  menuStyle: {
    // height: 17,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: useTheme().palette.primary.main
  },

  disableMenuStyle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: useTheme().palette.primary.main
  }
}))

export const WelloTab: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick
}: IProps) => {
  const classes = useStyles()

  return (
    <div onClick={onClick}>
      <Box
        borderBottom={isSelected ? 3 : 0}
        style={{ color: useTheme().palette.primary.main, width: 'auto' }}
      >
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'        
        >
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item>
              {isSelected && (
                <Box>
                  <Typography className={classes.menuStyle} variant='h4'>
                    {textTitle}{' '}
                  </Typography>
                </Box>
              )}

              {isSelected === false && (
                <Box >
                  <Typography className={classes.disableMenuStyle} variant='h4'>
                    {textTitle}{' '}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
