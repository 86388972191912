import { Box, CircularProgress, Typography } from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import Requestor from '../api/requestor'
import ComposableFormFromCoolForm from './formWithCoolForm'
import {
  SectionGroupedSchema,
  getFormDataFromObservations,
  exisitngHistory,
  getObservationsForSchema
} from '../utils/observationFormUtils'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ViewFormWithAddFormInDialog } from '../component/viewFormWithAddFormInDialog'

export interface OCARendererProps {
  OCAFormProviderBaseURL: string
  formSection: string
  formCategory: string
  OCAFormProviderHeader: Object
  existingObservations?: R4.IObservation[]
  formName?: string
  autoSubmit?: boolean
  displayTableOfContents?: boolean
  displayAsPopup?: boolean
  showAdded?:boolean
  dialogProps?: {
    open: boolean
    onClose: () => void
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  }

  isViewOnly?: boolean
  showForObjective?:boolean

  onDelete?: (
    observation: R4.IObservation,
    onSuccess?: (data: any) => void,
    onError?: (data: any) => void
  ) => void
  onSubmit?: (
    observation: R4.IObservation,
    onSuccess?: (data: any) => void,
    onError?: (data: any) => void
  ) => void

  onEdit?: (
    observation: R4.IObservation,
    onSuccess?: (data: any) => void,
    onError?: (data: any) => void
  ) => void
}

export const OCARenderer = (
  props: React.PropsWithChildren<OCARendererProps>
) => {
  const [formSchemas, setSchemaBase] =
    useState<SectionGroupedSchema[] | Schema | null>(null)
  const [fetching, setFetching] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    setFetching(true)
    Requestor.getOcaForms({
      ocaURL: props.OCAFormProviderBaseURL,
      ocaCategory: props.formCategory,
      ocaSection: props.formSection,
      requestHeaders: props.OCAFormProviderHeader,
      ocaFileName: props.formName
    })
      .then((schema) => {
        setFetching(false)
        setSchemaBase(schema)
      })
      .catch((e) => {
        setMessage(`Error: ${e.message}`)
      })
  }, [])
  if (fetching)
    return (
      <div>
        {' '}
        <CircularProgress color='primary' size='20px' />
      </div>
    )
  if (message) return <div>{message}</div>
  if (formSchemas != null) {
    if (props.formName && props.formName.length > 0) {
      const schema: Schema = formSchemas as Schema

      if (
        schema.formRenderType === 'Subjective' ||
        schema.formRenderType === 'Objective' ||
        schema.formRenderType === 'Assessment'
      
      ) {
        return (
          <ViewFormWithAddFormInDialog
            key={schema.uuid ?? schema.title}
            isViewOnly={props.isViewOnly}
            showAdd={props.showAdded}
           
            formSchema={{
              id: schema.uuid ?? schema.title,
              name: '',
              title: schema.title,
              schema: schema,
              data:getFormDataFromObservations(
                props.existingObservations,
                schema
              ),
              onSubmit: (submitForm, onSuccess, onError) => {
                console.log('----------submitForm------------', submitForm)
                if (props.onSubmit) {
                  props.onSubmit(submitForm, onSuccess, onError)
                }
              },
              onEdit: (submitForm, onSuccess, onError) => {
                console.log('----------submitForm------------', submitForm)
                if (props.onEdit) {
                  props.onEdit(submitForm, onSuccess, onError)
                }
              },
              onDelete: (deleteForm, onSuccess, onError) => {
                console.log('deleteForm', deleteForm)
                if (props.onDelete) {
                  props.onDelete(
                    props.existingObservations.find(
                      (observation) => observation.id === deleteForm
                    )
                  )
                  console.log('on success', deleteForm)
                }

                onSuccess({})

                console.log('on success', deleteForm)
              },
              
              OCAFormProviderURL: props.OCAFormProviderBaseURL,

              OCAFormProviderHeader: props.OCAFormProviderHeader
            }}
            existingObservations={getObservationsForSchema(
              props.existingObservations,
              schema
            )}
          />
        )
      } else {
        return (
          <ComposableFormFromCoolForm
            key={schema.uuid ?? schema.title}
            id={schema.uuid ?? schema.title}
            name=''
            title={schema.title}
            isViewOnly={props.isViewOnly}
            schema={schema}
            onSubmit={(submitForm) => {
              if (props.onSubmit) {
                props.onSubmit(submitForm)
              }
            }}
            onDelete={(deleteForm, onSuccess, onError) => {
              console.log('deleteForm', deleteForm)
              if (props.onDelete) {
                props.onDelete(
                  props.existingObservations.find(
                    (observation) => observation.id === deleteForm
                  )
                )
                console.log('on success', deleteForm)
              }

              onSuccess({})
            }}
            data={getFormDataFromObservations(
              props.existingObservations,
              schema
            )}
            OCAFormProviderURL={props.OCAFormProviderBaseURL}
            OCAFormProviderHeader={props.OCAFormProviderHeader}
          />
        )
      }
    }
  }

  if (formSchemas == null) return <></>

  const sectionGroupedSchema: SectionGroupedSchema[] =
    formSchemas as SectionGroupedSchema[]
  console.log('----------sectionGroupedSchema-----------', sectionGroupedSchema)

  return (
    <Box>
      {sectionGroupedSchema &&
        sectionGroupedSchema
          .sort(
            (a, b) => (a.sectionPositionInList ?? 0) - b.sectionPositionInList
          )
          .map((sectionSchema, index) => {
            return (
              <Box display='flex' flexDirection='column' width='100%'>
                {sectionSchema.sectionName &&
                  sectionSchema.sectionName.length > 0 && (
                    <Box display='flex' flexDirection='row' py={0.5}>
                      <Typography variant='h6'>
                        {sectionSchema.sectionName}
                      </Typography>
                    </Box>
                  )}
                {sectionSchema.forms
                  .sort(
                    (a, b) =>
                      (a.subSectionPositionInSection ?? 0) -
                      b.subSectionPositionInSection
                  )
                  .map((subSectionGroup, index) => {
                    return (
                      <Box display='flex' flexDirection='column' width='100%'>
                        {subSectionGroup.subSectionName &&
                          subSectionGroup.subSectionName.length > 0 && (
                            <Box display='flex' flexDirection='row' py={0.5}>
                              <Typography variant='subtitle1'>
                                {subSectionGroup.subSectionName}
                              </Typography>
                            </Box>
                          )}
                        {subSectionGroup.forms
                          .sort(
                            (a, b) =>
                              (a.categoryPositionInSubSection ?? 0) -
                              (b.categoryPositionInSubSection ?? 0)
                          )
                          .map((categoryGroup, index) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              {categoryGroup.categoryName &&
                                categoryGroup.categoryName.length > 0 && (
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    py={0.5}
                                  >
                                    <Typography
                                      style={{
                                        color: 'black',
                                        fontWeight: 900
                                      }}
                                    >
                                      {categoryGroup.categoryName}
                                    </Typography>
                                  </Box>
                                )}
                              {categoryGroup.forms
                                .sort(
                                  (a, b) =>
                                    (a.formIndex ?? 0) - (b.formIndex ?? 0)
                                )
                                .map((schema, categoryIndex) => (
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    style={{
                                      backgroundColor:
                                        schema.formRenderType === undefined
                                          ? categoryIndex % 2 === 0
                                            ? '#f5f5f5'
                                            : '#ffffff'
                                          : '#ffffff'
                                    }}
                                  >
                                    {(schema.formRenderType === 'Subjective' ||
                                      schema.formRenderType === 'Objective' ||
                                      schema.formRenderType ===
                                        'Assessment') && props.displayAsPopup === false && (
                                      <ViewFormWithAddFormInDialog
                                        key={schema.uuid ?? schema.title}
                                        showAdd={props.showAdded}
                                     
                                        isViewOnly={props.isViewOnly}
                                        formSchema={{
                                          id: schema.uuid ?? schema.title,
                                          name: '',
                                          data:getFormDataFromObservations(
                                            props.existingObservations,
                                            schema
                                          ),
                                          title: schema.title,
                                          schema: schema,
                                          onSubmit: (
                                            submitForm,
                                            onSuccess,
                                            onError
                                          ) => {
                                            console.log(
                                              '----------submitForm------------',
                                              submitForm
                                            )
                                            if (props.onSubmit) {
                                              props.onSubmit(
                                                submitForm,
                                                onSuccess,
                                                onError
                                              )
                                            }
                                          },
                                          onDelete: (
                                            deleteForm,
                                            onSuccess,
                                            onError
                                          ) => {
                                            console.log(
                                              'deleteForm',
                                              deleteForm
                                            )
                                            if (props.onDelete) {
                                              props.onDelete(
                                                props.existingObservations.find(
                                                  (observation) =>
                                                    observation.id ===
                                                    deleteForm
                                                )
                                              )
                                              console.log(
                                                'on success',
                                                deleteForm
                                              )
                                            }

                                            onSuccess({})

                                            console.log(
                                              'on success',
                                              deleteForm
                                            )
                                          },
                                          onEdit: (submitForm, onSuccess, onError) => {
                                            console.log('----------submitForm------------', submitForm)
                                            if (props.onEdit) {
                                              props.onEdit(submitForm, onSuccess, onError)
                                            }
                                          },
                                          OCAFormProviderURL:
                                            props.OCAFormProviderBaseURL,

                                          OCAFormProviderHeader:
                                            props.OCAFormProviderHeader
                                        }}
                                        existingObservations={getObservationsForSchema(
                                          props.existingObservations,
                                          schema
                                        )}
                                      />
                                    )}

{(schema.formRenderType === 'Subjective' ||
                                      schema.formRenderType === 'Objective' ||
                                      schema.formRenderType ===
                                        'Assessment') && props.displayAsPopup  && (
                                      <ComposableFormFromCoolForm
                                        key={schema.uuid ?? schema.title}
                                        id={schema.uuid ?? schema.title}
                                        name=''
                                        title={schema.title}
                                        schema={schema}
                                        onSubmit={(submitForm) => {
                                          if (props.onSubmit) {
                                            props.onSubmit(submitForm)
                                          }
                                        }}
                                        onDelete={(
                                          deleteForm,
                                          onSuccess,
                                          onError
                                        ) => {
                                          console.log('deleteForm', deleteForm)
                                          if (props.onDelete) {
                                            props.onDelete(
                                              props.existingObservations.find(
                                                (observation) =>
                                                  observation.id === deleteForm
                                              )
                                            )
                                            console.log(
                                              'on success',
                                              deleteForm
                                            )
                                          }

                                          onSuccess({})
                                        }}
                                        data={getFormDataFromObservations(
                                          props.existingObservations,
                                          schema
                                        )}
                                        OCAFormProviderURL={
                                          props.OCAFormProviderBaseURL
                                        }
                                        OCAFormProviderHeader={
                                          props.OCAFormProviderHeader
                                        }
                                      />
                                    )}

                                    
                                    {schema.formRenderType === undefined  && (
                                      <ComposableFormFromCoolForm
                                        key={schema.uuid ?? schema.title}
                                        id={schema.uuid ?? schema.title}
                                        name=''
                                        title={schema.title}
                                        schema={schema}
                                        onSubmit={(submitForm) => {
                                          if (props.onSubmit) {
                                            props.onSubmit(submitForm)
                                          }
                                        }}
                                        onDelete={(
                                          deleteForm,
                                          onSuccess,
                                          onError
                                        ) => {
                                          console.log('deleteForm', deleteForm)
                                          if (props.onDelete) {
                                            props.onDelete(
                                              props.existingObservations.find(
                                                (observation) =>
                                                  observation.id === deleteForm
                                              )
                                            )
                                            console.log(
                                              'on success',
                                              deleteForm
                                            )
                                          }

                                          onSuccess({})
                                        }}
                                        data={getFormDataFromObservations(
                                          props.existingObservations,
                                          schema
                                        )}
                                        OCAFormProviderURL={
                                          props.OCAFormProviderBaseURL
                                        }
                                        OCAFormProviderHeader={
                                          props.OCAFormProviderHeader
                                        }
                                      />
                                    )}
                                  </Box>
                                ))}
                            </Box>
                          ))}
                      </Box>
                    )
                  })}

                <Box display='flex' flexDirection='row'></Box>
              </Box>
            )
          })}
    </Box>
  )
}
