import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

export interface SOAPIndicativeElementProps {
  title: string
  description: string
  showIndicativeBars: boolean
  totalBarCount?: number
  filledBarCount?: number
  showAlarmingPrefixIcon?: boolean
  timeData?: string
  severity?: 'Severe' | 'Moderate' | 'Mild'
  type?:
    | 'condition'
    | 'medication'
    | 'surgery'
    | 'allergy'
    | 'lab_result'
    | 'vitals'
    | 'other'
}
export const SOAPIndicativeElement: React.FC<SOAPIndicativeElementProps> = ({
  title,
  description,
  showIndicativeBars,
  filledBarCount = 0,
  totalBarCount = 4,
  showAlarmingPrefixIcon = false,
  timeData,
  severity = 'Mild',
  type = 'other',
}: SOAPIndicativeElementProps) => (
  <Box p={1} key={`${title}_${description}`}>
    <Grid container style={{ padding: 0 }}>
      <Grid xs={5} style={{ padding: 0 }}>
        <Box display='flex' flexDirection='row'>
          {showAlarmingPrefixIcon && (
            <Box paddingX={0.2}>
              <Typography color={severity === 'Severe' ? 'error' : 'primary'}>
                {'\u2B24'}
              </Typography>
            </Box>
          )}
          <Box flexGrow={1}>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              {title}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {type === 'vitals' && (
        <Grid xs={4} style={{ padding: 0 }}>
          <Box
            display='flex'
            flexDirection='row'
            alignItems={type === 'vitals' ? 'flex-start' : 'flex-end'}
          >
            {!showIndicativeBars && (
              <Box paddingX={0.1}>
                {type === 'vitals' && (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_record_vitals.png`}
                    alt='vital'
                    style={{ height: '1em' }}
                  />
                )}{' '}
              </Box>
            )}
            {showIndicativeBars && (
              <Box display='flex' flexDirection='row' style={{ padding: 0 }}>
                {[...Array(totalBarCount)].map((val, index) => (
                  <Box paddingX={0.1} key={`${title}_${val}`}>
                    <Typography
                      variant='subtitle2'
                      color={
                        index < filledBarCount
                          ? severity === 'Severe'
                            ? 'error'
                            : 'primary'
                          : 'textSecondary'
                      }
                    >
                      {'\u2759'}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box flexGrow={1} px={showIndicativeBars ? 0.5 : 1}>
              <Typography
                variant='subtitle2'
                color={
                  filledBarCount >= 1
                    ? severity === 'Severe'
                      ? 'error'
                      : 'initial'
                    : 'inherit'
                }
              >
                {' '}
                {description}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      {type !== 'vitals' && (
        <Grid xs={7} style={{ padding: 0 }}>
          <Box display='flex' flexDirection='row' alignItems='flex-end'>
            {!showIndicativeBars && (
              <Box paddingX={0.1}>
                {type === 'allergy' && (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_record_allergy.png`}
                    alt='allergy'
                    style={{ height: '1em' }}
                  />
                )}{' '}
                {type === 'surgery' && (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_record_procedures.png`}
                    alt='surgery'
                    style={{ height: '1em' }}
                  />
                )}{' '}
                {type === 'medication' && (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_record_medication.png`}
                    alt='medication'
                    style={{ height: '1em' }}
                  />
                )}{' '}
                {type === 'lab_result' && (
                  <img
                    src={`${process.env.PUBLIC_URL}/icon_record_lab_result.png`}
                    alt='Lab result'
                    style={{ height: '1em' }}
                  />
                )}{' '}
              </Box>
            )}
            {showIndicativeBars && (
              <Box display='flex' flexDirection='row' style={{ padding: 0 }}>
                {[...Array(totalBarCount)].map((val, index) => (
                  <Box paddingX={0.1} key={`${title}_${val}`}>
                    <Typography
                      variant='subtitle2'
                      color={
                        index < filledBarCount
                          ? severity === 'Severe'
                            ? 'error'
                            : 'primary'
                          : 'textSecondary'
                      }
                    >
                      {'\u2759'}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box
              flexGrow={1}
              px={showIndicativeBars ? 0.5 : 1}
              alignContent='center'
            >
              <Typography
                variant='subtitle2'
                color={
                  filledBarCount >= 1
                    ? severity === 'Severe'
                      ? 'error'
                      : 'initial'
                    : 'inherit'
                }
              >
                {' '}
                {description}
              </Typography>
            </Box>
            {type === 'other' && (
              <Box flexGrow={1} px={showIndicativeBars ? 0.5 : 0}>
                <Typography
                  variant='subtitle2'
                  color={
                    filledBarCount >= 1
                      ? severity === 'Severe'
                        ? 'error'
                        : 'initial'
                      : 'inherit'
                  }
                >
                  {' '}
                  {timeData ?? ''}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  </Box>
)
