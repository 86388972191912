import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { theme } from '../../Common/Theme/theme'

export interface IHeader {
  title?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  TopPaper: {
    maxWidth: 960,
    height: 48,
    backgroundColor: theme.palette.primary.main
  },

  topHeader: {
    paddingTop: theme.spacing(1),
    color: theme.palette.common.white
  }
}))

export const Header: React.FC<IHeader> = ({ title }: IHeader) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Paper className={classes.TopPaper} variant='outlined' square>
        <Grid container justify='center' spacing={0}>
          <Typography variant='h6' className={classes.topHeader}>
            {title}{' '}
          </Typography>
        </Grid>
      </Paper>
    </div>
  )
}
