import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { titleCase } from '../../../utils/fhirHelpers/patientHelper'

interface WelloFormItemLabelProps {
  title: string
  displayDoneLabel?: boolean
}

export const WelloFormItemLabel: React.FunctionComponent<WelloFormItemLabelProps> =
  (props: WelloFormItemLabelProps) => {
    return (
      <Box py={1} px={0.25} display='flex' flexDirection='row'>
        <Typography
          variant='subtitle2'
           style={{
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 'normal',
           }}
        >
          {titleCase(props.title)}
        </Typography>
        {props.displayDoneLabel && (
          <Box px={0.25}>
            <FontAwesomeIcon icon={faCheckCircle} color='#0091ff' />
          </Box>
        )}
      </Box>
    )
  }

export default WelloFormItemLabel
