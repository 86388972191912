/* eslint-disable react/jsx-key */
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { TimerTab } from './TimerTab'
import { Paper, Typography } from '@material-ui/core'

interface TabProps {
  preSelectedTab?: string
  onTabChange: (selectedTab: string) => void
  menu: string[]
}
const useStyles = makeStyles((theme) => ({
  paperHeight: {
    height: 37,
    backgroundColor: '#dcdcdc'
  }
}))

export const TimeTabs: React.FC<TabProps> = ({
  preSelectedTab,
  onTabChange,
  menu
}: TabProps) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(preSelectedTab)
  const handleChange = (selectedTab: string) => {
    onTabChange(selectedTab)
    setSelectedTab(selectedTab)
  }

  return (
    <Paper
      className={classes.paperHeight}
      style={{ backgroundColor: '#dcdcdc' }}
    >
      <Grid container direction='row'>
        {menu.map((value, i) => (
          <Grid item>
            <TimerTab
              textTitle={value}
              isSelected={selectedTab === value}
              onClick={() => handleChange(value)}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}
