import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import MailIcon from '@material-ui/icons/Mail'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

export function TopBadge() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Badge badgeContent={4} color='primary'>
        <MailIcon />
      </Badge>
    </div>
  )
}
