import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core'
import React from 'react'
import { WelloLoadingIndicator } from './loading'

interface ILoadingPageProps {
  title: string
  loadingMessage?: string
}

export const WelloLoadingPage: React.FC<ILoadingPageProps> = ({
  title,
  loadingMessage
}: ILoadingPageProps) => {
  return (
    <Box height='700'>
      <Container maxWidth='xl'>
        <AppBar position='static'>
          <Toolbar>
            <Typography variant='h6'>{title ?? 'Wello Apps'}</Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction='column'
          xs={12}
          justify='flex-end'
          alignItems='stretch'
        >
          <Grid item style={{ padding: 48 }}>
            <WelloLoadingIndicator
              message={loadingMessage}
            ></WelloLoadingIndicator>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
