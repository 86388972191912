import { R4 } from '@ahryman40k/ts-fhir-types'
import { Grid, makeStyles, Paper } from '@material-ui/core'
import { randomInt } from 'crypto'
import React, { useState } from 'react'
import { TabsComponent } from '../../../LayoutComponent/Subcomponent/TabsComponent'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import Popup from '../../../PrmitiveComponent/component/Helper/Popup'
import { NewPatient } from '../../Form/Modal/NewPatient'
import { Header } from '../../Form/Header'
import { LeftFilterOptions } from '../../Subcomponent/LeftFilterOptions'
import { WelloCalendar } from '../Appointments/WelloCalendar'
import { MultiSelectWithSearch } from '../../../PrmitiveComponent/component/MultiSelectWithFilter'

export interface IUnitAdmin {
  children?: React.ReactNode
  menu?: string[]
}
const menu = ['DAYS', 'WEEK', 'MONTH']
const useStyles = makeStyles((theme) => ({
  subHeaderPaper: {
    maxWidth: 989,
    minWidth: 989,
    height: 40,
    borderRadius: 1,
    backgroundColor: '#ececec'
  }
}))

export const AppointmentList: React.FC = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [openPopup, setOpenPopup] = useState(false)
  const [recordForEdit, setRecordForEdit] = useState(null)

  const handleChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
  }

  const onCancel = (value: boolean) => {
    setOpenPopup(value)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper>
          <Grid container direction='row'>
            <Grid item xs={4}>
              <TabsComponent
                preSelectedTab={selectedTab}
                onTabChange={(e) => handleChangeTab(e)}
                menu={menu}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end'>
                <Controls.WelloButton
                  type='button'
                  text='Add Patient'
                  size='small'
                  backgroundColor='#4543d9'
                  color='#ffffff'
                  onClick={() => {
                    setOpenPopup(true)
                    setRecordForEdit(null)
                  }}
                />

                <Controls.WelloButton
                  type='button'
                  text='Add Appointment'
                  size='small'
                  backgroundColor='#4543d9'
                  color='#ffffff'
                  // onClick={() => {
                  //   dispatch(showSuccessAlert('Testing'))
                  // }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Popup
        title='Add Expert'
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        {/* <NewPatient
          count={2}
          
          onClickToCancel={onCancel}
          onValidSubmit={(
            patientDetail,
            relatedPersonDetail,
            occupation,
            nationality
          ) => {
            console.log(patientDetail)
            console.log(occupation)
            console.log(nationality)
            if (relatedPersonDetail) {
              console.log(relatedPersonDetail)
              setOpenPopup(false)
            }
          }}
        /> */}
      </Popup>
    </Grid>
  )
}
