import { WelloButton } from './WelloButton'
import { WelloInput } from './WelloInput'
import { WelloActionButton } from './WelloActionButton'
import { WelloSelect } from './WelloSelect'
import { WelloTextArea } from './TextArea'
import { WelloRadioGroup } from './WelloRadioGroup'
import { WelloInputFile } from './WelloInputFile'
import { PhoneNumber } from './PhoneNumber'
import { WelloMultiSelectBox } from './WelloMultiSelectBox'
import { WelloCheckbox } from './WelloCheckbox'
import { WelloCheckboxColored } from './WelloColoredCheckBox'
import { WelloInputBox } from './WelloInputBox'
import { WelloSelectBox } from './WelloSelectBox'
import { WelloDate } from './WelloDate'
import WelloInputTab from './WelloInputTab'
import {WelloTextAreaAddress} from './WelloTextArea'
import { WelloSearchFilter } from './WelloSearchFilter'
import { WelloAutoComplete } from './WelloAutoComplete'
import { WelloEmailInput } from './WelloEmailInput'
import { WelloSelectGender } from './WelloSelectGender'
import { WelloType } from './WelloType'
import { WelloSelectNationality } from './WelloSelectNationality'
import { WelloOccupation } from './WelloOccupation'
import { WelloAddress } from './WelloAddress'
import { WelloTimePicker } from './WelloTimePicker'
import { WelloDatePicker } from './WelloDatePicker'
import { WelloSelectForm } from './WelloSelectForm'
import { WelloSelectDoctor } from './WelloSelectDoctor'
import { WelloSelectFhirPractitioner } from './WelloSelectFhirPractitioner'
import { WelloButtonTab } from './WelloButtonTab'
import { WelloPhoneNumberField } from './WelloPhoneNumberField'
import { WelloPassportExpiry } from "./passportExpiryDate"
export const Controls = {
  WelloButton,
  WelloInput,
  WelloActionButton,
  WelloSelect,
  WelloTextArea,
  WelloRadioGroup,
  WelloInputFile,
  PhoneNumber,
  WelloMultiSelectBox,
  WelloCheckbox,
  WelloInputBox,
  WelloSelectBox,
  WelloCheckboxColored,
  WelloDate,
  WelloInputTab,
  WelloSearchFilter,
  WelloAutoComplete,
  WelloEmailInput,
  WelloSelectGender,
  WelloType,
  WelloSelectNationality,
  WelloOccupation,
  WelloAddress,
  WelloTimePicker,
  WelloDatePicker,
  WelloSelectForm,
  WelloSelectDoctor,
  WelloSelectFhirPractitioner,
  WelloButtonTab,
  WelloPhoneNumberField,
  WelloTextAreaAddress,
  WelloPassportExpiry
}
