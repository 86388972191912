import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import { Avatar, Grid } from '@material-ui/core'
import moment from 'moment'
import maleIcon from './../../assets/images/m.png'
import femaleIcon from './../../assets/images/f.png'

interface Data {
  serviceName: string | undefined
  methodOfDelivery: string
  summary: string
  recommendedDuration: string
  rate: string
  category: string
  benefits: string
}

interface ICardProperties {
  serviceData: any[]
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#dcdcdc',
      color: '#4543d9',
      wordWrap: 'break-word'
    },
    column: {
      wordWrap: 'break-word'
    },
    body: {
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: 'OpenSans-Bold'
    }
  })
)(TableCell)

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'serviceName',
    numeric: true,
    disablePadding: true,
    label: 'Service Name'
  },
  {
    id: 'methodOfDelivery',
    numeric: false,
    disablePadding: false,
    label: 'Method'
  },

  {
    id: 'summary',
    numeric: false,
    disablePadding: false,
    label: 'Summary'
  },

  {
    id: 'recommendedDuration',
    numeric: false,
    disablePadding: false,
    label: 'Recommended Duration'
  },

  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    label: 'Price'
  },

  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category'
  },

  {
    id: 'benefits',
    numeric: true,
    disablePadding: false,
    label: 'Benefits'
  }
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles({
  dotIcon: {
    height: 12
  },

  image: {
    width: 30,
    height: 30
  },
  overlappimg: {
    height: 20,
    // width: 20,
    align: 'center'
  },
  container: {
    height: '90vh',
    width: 'auto',
    scrollbarWidth: 'auto',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  table: {
    overflow: 'hidden'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'white'
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#ececec'
      }
    }
  })
)(TableRow)

export const ServiceTable: React.FC<ICardProperties> = ({
  serviceData
}: ICardProperties) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('serviceName')
  const [dense, setDense] = React.useState(false)
  const [image, setImage] = React.useState('')

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label='sticky table'>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(serviceData, getComparator(order, orderBy)).map(
            (row, i) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell component='th' scope='row'>
                    <div style={{ display: 'flex', alignItems: 'left' }}>
                      {/* <img className={classes.dotIcon} src="red.png" alt="" /> */}
                      <Avatar
                        className={classes.image}
                        alt='Profile'
                        src={row.photoUri}
                      />{' '}
                      &nbsp;
                      <Typography variant='h4' gutterBottom color='primary'>
                        {' '}
                        {row.serviceName}{' '}
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ color: '#4543d9', alignItems: 'center' }}
                  >
                    <Typography variant='h4' gutterBottom color='primary'>
                      {row.methodOfDelivery}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      color: '#4543d9',
                      alignItems: 'center',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography variant='h4' gutterBottom color='primary'>
                      {row.summary}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      color: '#4543d9',
                      alignItems: 'center',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography variant='h4' gutterBottom color='primary'>
                      {row.recommendedDuration}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography variant='h4' gutterBottom color='primary'>
                      &#8377; {row.rate}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography variant='h4' gutterBottom color='primary'>
                      {row.category}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography variant='h4' gutterBottom color='primary'>
                      {row.benefits.join(' , ')}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
