import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import AddIcon from '@material-ui/icons/Add'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

interface filterProps {
  onClickFilter: (filters: string[]) => void
  filterLabel: string
}
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1),
    marginTop: 13
  },
  formControl: {
    margin: theme.spacing(0)
  }
}))

export const TopFilter: React.FC<filterProps> = ({
  onClickFilter,
  filterLabel
}: filterProps) => {
  const handleClick = () => {
    const fileterValues: string[] = []
    fileterValues.push('This Week')
    fileterValues.push('This Month')
    fileterValues.push('This Year')
    onClickFilter(fileterValues)
  }
  const classes = useStyles()

  return (
    <div>
      <Typography
        align='left'
        variant='h6'
        color='primary'
        className={classes.display}
      >
        &nbsp;{' '}
        <Chip
          icon={<HighlightOffIcon />}
          onClick={handleClick}
          label={filterLabel}
          variant='outlined'
          color='primary'
        />{' '}
        <Chip
          icon={<AddIcon />}
          label='Add Filter'
          variant='outlined'
          color='primary'
        />
      </Typography>
      <br></br>
    </div>
  )
}

export default TopFilter
