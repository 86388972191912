import React from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import male from '../../assets/images/m.png'
import female from '../../assets/images/f.png'

interface Data {
  name: string | undefined
  gender: string
  medicalQualification: string[]
  yearsOfExperience: string
  profileDescription: string
  testimonials: string
  languages: any[]
  regNo: string
}

interface ICardProperties {
  patientData: any[]
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
      wordWrap: 'break-word'
    },
    column: {
      wordWrap: 'break-word'
    },
    body: {
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: 'OpenSans-Bold'
    }
  })
)(TableCell)

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: true,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: 'Gender'
  },

  {
    id: 'medicalQualification',
    numeric: false,
    disablePadding: false,
    label: 'Medical Qualification'
  },

  {
    id: 'yearsOfExperience',
    numeric: false,
    disablePadding: false,
    label: 'Years Of Experience'
  },

  {
    id: 'profileDescription',
    numeric: false,
    disablePadding: false,
    label: 'Description'
  },

  {
    id: 'testimonials',
    numeric: false,
    disablePadding: false,
    label: 'Testimonials'
  },

  {
    id: 'regNo',
    numeric: false,
    disablePadding: false,
    label: 'Registration Number'
  },
  {
    id: 'languages',
    numeric: false,
    disablePadding: false,
    label: 'Languages'
  }
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label} &nbsp;
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 1305
  },
  dotIcon: {
    height: 12
  },

  image: {
    width: 30,
    height: 30
  },
  overlapping: {
    height: 20,
    // width: 20,
    align: 'center'
  },
  container: {
    maxHeight: 425
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  })
)(TableRow)

const TableComp: React.FC<ICardProperties> = ({
  patientData
}: ICardProperties) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name')

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label='sticky table'>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(patientData, getComparator(order, orderBy)).map(
            (row, i) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell component='th' scope='row'>
                    <div style={{ display: 'flex', alignItems: 'left' }}>
                      {/* <img className={classes.dotIcon} src="red.png" alt="" /> */}

                      <Typography
                        variant='subtitle2'
                        gutterBottom
                        color='primary'
                      >
                        {' '}
                        {row.name}{' '}
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    color='primary'
                    // style={{ color:'prima', alignItems: 'center' }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.gender === 'male' && (
                        <Typography
                          variant='subtitle2'
                          gutterBottom
                          color='primary'
                        >
                          <img
                            className={classes.overlapping}
                            src={male}
                            alt=''
                          />{' '}
                          &nbsp;
                          {'Male'}
                        </Typography>
                      )}

                      {row.gender === 'female' && (
                        <Typography
                          variant='subtitle2'
                          gutterBottom
                          color='primary'
                        >
                          <img
                            className={classes.overlapping}
                            src={female}
                            alt=''
                          />{' '}
                          &nbsp;
                          {'Female'}
                        </Typography>
                      )}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    color='primary'
                    style={{
                      // color: '#4543d9',
                      alignItems: 'center',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.medicalQualification}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    color='primary'
                    style={{
                      // color: '#4543d9',
                      alignItems: 'center',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.yearsOfExperience}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    color='primary'
                    style={{
                      // color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.profileDescription}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    color='primary'
                    style={{
                      // color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.testimonials}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    color='primary'
                    style={{
                      // color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.regNo}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    color='primary'
                    style={{
                      // color: '#4543d9',
                      alignItems: 'left',
                      wordWrap: 'break-word'
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      color='primary'
                    >
                      {row.languages}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default TableComp
