import React from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControl, FormHelperText, Typography } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import dotIcon from '../../../assets/images/dot_icon.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300
    }
  })
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export interface IProps {
  name: any
  label: any
  value?: any
  onChange?: any
  options: any
  width?: number
  error?: null | undefined
  type?: any
}

export const WelloMultiSelectBox: React.FC<IProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  width,
  error,
  type
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl className={classes.formControl}>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='h4'
      >
        &nbsp; {label}
      </Typography>
      <Select
        variant='outlined'
        labelId='demo-mutiple-checkbox-label'
        label={label}
        id={name}
        name={name}
        multiple
        value={value}
        onChange={onChange}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          maxWidth: 480,
          // height: 38,
          width: width,
          fontSize: 13,
          backgroundColor: useTheme().palette.common.white
        }}
        required={true}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        <MenuItem dense value=''>
          {type}
        </MenuItem>
        {options.map((name: any) => (
          <MenuItem dense key={name} value={name}>
            <Checkbox checked={value.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText
          style={{
            color: useTheme().palette.error.main,
            fontWeight: 400,
            fontSize: 12,
            fontFamily: 'Open Sans'
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
