import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'

export interface IProps {
  name: string
  label: string
  isChecked?: boolean
  id: any
  onChange?: (isSelected: boolean) => void
  isInline?: boolean
  error?: null | undefined
}
const useStyles = makeStyles((theme) => ({
  checked: {
    '&, & + $label': {
      color: useTheme().palette.primary.main
    }
  },
  label: {}
}))

export const WelloCheckbox: React.FC<IProps> = ({
  name,
  label,
  id,
  isChecked,
  onChange,
  isInline,
  error
}: IProps) => {
  const classes = useStyles()
  return (
    <FormGroup>
      <FormControl>
        <FormControlLabel
          classes={{
            label: classes.label
          }}
          control={
            <MuiCheckbox
              id={id}
              name={name}
              color='primary'
              classes={{
                checked: classes.checked
              }}
              checked={isChecked}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label={<Typography variant='subtitle1'>{label}</Typography>}
          {...(error && { error: true, helperText: error })}
        />
      </FormControl>
    </FormGroup>
  )
}
